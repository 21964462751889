import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Settings, Trophy, Edit2, Save, RotateCcw, Lock, Unlock, Plus, Trash2, Power, CheckCircle, XCircle, Heart, EyeOff, Eye, Play, Sparkles } from 'lucide-react';
import toast, { Toaster } from 'react-hot-toast';
import Dialog from './ui/Dialog';

interface Stage {
  id: number;
  position: "1st" | "2nd" | "3rd" | "4th" | "5th";
  prize: number;
  is_active: boolean;
  is_unlocked: boolean;
  minimum_score: number;
  colors: {
    from: string;
    via: string;
    to: string;
    shadow: string;
  };
}

interface NewStageDialog {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (data: { prize: number; minimum_score: number }) => void;
  title: string;
}


const NewStageDialog: React.FC<NewStageDialog> = ({ isOpen, onClose, onConfirm, title }) => {
  const [prize, setPrize] = useState<number>(0);
  const [minimumScore, setMinimumScore] = useState<number>(0);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (prize <= 0) {
      toast.error('Prize must be greater than 0');
      return;
    }
    
    onConfirm({ prize, minimum_score: 500 });
    setPrize(0);
    setMinimumScore(0);
  };

  if (!isOpen) return null;

  return (
    <motion.div
      className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-50 flex items-center justify-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={onClose}
    >
      <motion.div
        className="bg-[#1A1C23] rounded-lg p-6 w-[400px]"
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.95, opacity: 0 }}
        onClick={e => e.stopPropagation()}
      >
        <h3 className="text-xl font-bold text-white mb-4">{title}</h3>
        
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-4 mb-6">
            <div>
              <label className="text-gray-300 block mb-2">Prize (€)</label>
              <input
                type="number"
                value={prize}
                onChange={e => setPrize(Number(e.target.value))}
                className="w-full bg-[#252A34] text-white border border-[#611BE2] rounded px-3 py-2"
              />
            </div>
          </div>

          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 rounded bg-gray-700 text-white hover:bg-gray-600 transition-colors"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 rounded bg-[#611BE2] text-white hover:bg-[#8B5CF6] transition-colors"
            >
              Add Stage
            </button>
          </div>
        </form>
      </motion.div>
    </motion.div>
  );
};

const DeleteDialog: React.FC<{ isOpen: boolean; onClose: () => void; onConfirm: () => void }> = ({
  isOpen,
  onClose,
  onConfirm,
}) => {
  if (!isOpen) return null;

  return (
    <motion.div
      className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-50 flex items-center justify-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={onClose}
    >
      <motion.div
        className="bg-[#1A1C23] rounded-lg p-6 w-[400px]"
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.95, opacity: 0 }}
        onClick={e => e.stopPropagation()}
      >
        <h3 className="text-xl font-bold text-white mb-4">Delete Stage</h3>
        <p className="text-gray-300 mb-6">Are you sure you want to delete this stage? This action cannot be undone.</p>

        <div className="flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 rounded bg-gray-700 text-white hover:bg-gray-600 transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              onConfirm();
              onClose();
            }}
            className="px-4 py-2 rounded bg-red-600 text-white hover:bg-red-700 transition-colors"
          >
            Delete
          </button>
        </div>
      </motion.div>
    </motion.div>
  );
};

const API_URL = 'https://empire-back.selaris.app/api';



const ExtraLifeHideControl: React.FC = () => {
  const [hideStatus, setHideStatus] = useState(false);
  
  const fetchHideStatus = async () => {
    try {
      const response = await fetch(`${API_URL}/extra-life-hide`);
      const data = await response.json();
      if(data.is_active === true){
        setHideStatus(false);
      }
      else if(data.is_active === false){
        setHideStatus(true);
      }
    } catch (error) {
      console.error('Failed to fetch Extra Life hide status:', error);
    }
  };

  useEffect(() => {
    fetchHideStatus();
    // Polling toutes les 2 secondes
    const interval = setInterval(fetchHideStatus, 2000);
    return () => clearInterval(interval);
  }, []);

  const toggleHideStatus = async () => {
    const loadingToast = toast.loading(`${hideStatus ? 'Showing' : 'Hiding'} Extra Life overlay...`);
    try {
      const response = await fetch(`${API_URL}/extra-life-hide`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ is_active: hideStatus })
      });

      if (response.ok) {
        const data = await response.json();
        if(data.is_active === true){
          setHideStatus(false);
        }
        else if(data.is_active === false){
          setHideStatus(true);
        }
        toast.success(`Extra Life overlay ${hideStatus ? 'shown' : 'hidden'} successfully`, { id: loadingToast });
      } else {
        throw new Error('Failed to toggle hide status');
      }
    } catch (error) {
      console.error('Error toggling hide status:', error);
      toast.error('Failed to toggle hide status', { id: loadingToast });
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      className="mb-8 p-4 rounded-lg bg-[#252A34] border-l-4 border-blue-500"
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          {hideStatus ? (
            <EyeOff className="mr-3 text-gray-500" size={24} />
          ) : (
            <Eye className="mr-3 text-blue-500" size={24} />
          )}
          <div>
            <h2 className="text-white font-semibold text-lg">Extra Life Overlay</h2>
            <p className="text-gray-400 text-sm">
              {hideStatus ? 'Extra Life is currently hidden' : 'Extra Life is currently visible'}
            </p>
          </div>
        </div>
        
        <div className="flex items-center space-x-4">
          <button
            onClick={toggleHideStatus}
            className={`flex items-center px-4 py-2 rounded transition-colors ${
              hideStatus 
                ? 'bg-green-500 hover:bg-green-600' 
                : 'bg-red-500 hover:bg-red-600'
            } text-white font-medium`}
          >
            <Power className="mr-2" size={18} />
            {hideStatus ? 'Show Overlay' : 'Hide Overlay'}
          </button>
        </div>
      </div>
    </motion.div>
  );
};


const ConfettiControl: React.FC = () => {
  const [confettiStatus, setConfettiStatus] = useState(false);
  
  const fetchConfettiStatus = async () => {
    try {
      const response = await fetch(`${API_URL}/confetti-hidden`);
      const data = await response.json();
      if (data && data.status === "success") {
        if(data.data.is_active === true){
          setConfettiStatus(false);
        }
        else if(data.data.is_active === false){
          setConfettiStatus(true);
        }
      }
    } catch (error) {
      console.error('Failed to fetch Confetti status:', error);
      toast.error('Failed to fetch Confetti status');
    }
  };

  useEffect(() => {
    fetchConfettiStatus();
  }, []);

  const startConfettiAnimation = async () => {
    const loadingToast = toast.loading('Starting confetti animation...');
    try {
      const response = await fetch(`${API_URL}/confetti-hidden`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ is_active: true })
      });

      if (response.ok) {
        const data = await response.json();
        setConfettiStatus(false);
        toast.success('Confetti animation started!', { id: loadingToast });
        await new Promise(resolve => setTimeout(resolve, 10000));
        setConfettiStatus(true);
      } else {
        throw new Error('Failed to start confetti animation');
      }
    } catch (error) {
      console.error('Error starting confetti animation:', error);
      toast.error('Failed to start confetti animation', { id: loadingToast });
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      className="mb-8 p-4 rounded-lg bg-[#252A34] border-l-4 border-yellow-500"
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <Sparkles 
            className={`mr-3 ${confettiStatus ? 'text-gray-500' : 'text-yellow-500'}`} 
            size={24}
          />
          <div>
            <h2 className="text-white font-semibold text-lg">Confetti Animation</h2>
            <p className="text-gray-400 text-sm">
              {confettiStatus ? 'Animation ready to play' : 'Animation in progress'}
            </p>
          </div>
        </div>
        
        <div className="flex items-center space-x-4">
          <button
            onClick={startConfettiAnimation}
            disabled={!confettiStatus}
            className={`flex items-center px-4 py-2 rounded transition-colors ${
              confettiStatus 
                ? 'bg-yellow-500 hover:bg-yellow-600 cursor-pointer' 
                : 'bg-gray-600 cursor-not-allowed'
            } text-white font-medium`}
          >
            <Play className="mr-2" size={18} />
            Start Animation
          </button>
        </div>
      </div>
    </motion.div>
  );
};

const PrizeSettings: React.FC = () => {
  const [stages, setStages] = useState<Stage[]>([]);
  const [editingStage, setEditingStage] = useState<number | null>(null);
  const [editingValue, setEditingValue] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [extraLifeStatus, setExtraLifeStatus] = useState(false);
  const [isResetDialogOpen, setIsResetDialogOpen] = useState(false);
  const [isAddStageDialogOpen, setIsAddStageDialogOpen] = useState(false);
  const [stageToDelete, setStageToDelete] = useState<number | null>(null);

  const fetchStages = async () => {
    try {
      const response = await fetch(`${API_URL}/stages`);
      const data = await response.json();
      if (data.data) {
        setStages(data.data);
      }
      setLoading(false);
    } catch (error) {
      toast.error('Failed to fetch stages');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStages();
  }, []);

  const handlePrizeChange = async (id: number) => {
    const newPrize = parseInt(editingValue);
    if (isNaN(newPrize) || newPrize < 0) {
      toast.error('Please enter a valid prize amount');
      return;
    }

    const loadingToast = toast.loading('Updating prize...');
    try {
      const response = await fetch(`${API_URL}/stages/${id}/prize`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ prize: newPrize }),
      });

      if (response.ok) {
        const data = await response.json();
        const updatedStages = stages.map(stage => 
          stage.id === id ? { ...stage, prize: newPrize } : stage
        );
        setStages(updatedStages);
        toast.success('Prize updated successfully', { id: loadingToast });
        setEditingStage(null);
        setEditingValue("");
      } else {
        throw new Error('Failed to update prize');
      }
    } catch (error) {
      toast.error('Failed to update prize', { id: loadingToast });
    }
  };

  const handleStageActivation = async (id: number, isActivating: boolean) => {
    const loadingToast = toast.loading(`${isActivating ? 'Activating' : 'Deactivating'} stage...`);
    try {
      const response = await fetch(`${API_URL}/stages/${id}/activate`, {
        method: 'PATCH',
      });

      if (response.ok) {
        const data = await response.json();
        const updatedStages = stages.map(stage => ({
          ...stage,
          is_active: stage.id === id ? true : false
        }));
        setStages(updatedStages);
        toast.success(`Stage ${isActivating ? 'activated' : 'deactivated'} successfully`, { id: loadingToast });
      } else {
        throw new Error(`Failed to ${isActivating ? 'activate' : 'deactivate'} stage`);
      }
    } catch (error) {
      toast.error(`Failed to ${isActivating ? 'activate' : 'deactivate'} stage`, { id: loadingToast });
    }
  };

  const toggleStageLock = async (id: number, currentStatus: boolean) => {
    const action = currentStatus ? 'Locking' : 'Unlocking';
    const loadingToast = toast.loading(`${action} stage...`);
    
    try {
      const response = await fetch(`${API_URL}/stages/${id}/unlock`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ isUnlocked: !currentStatus }),
      });

      if (response.ok) {
        const data = await response.json();
        const updatedStages = stages.map(stage => 
          stage.id === id ? { ...stage, is_unlocked: !currentStatus } : stage
        );
        setStages(updatedStages);
        toast.success(`Stage ${currentStatus ? 'locked' : 'unlocked'} successfully`, { id: loadingToast });
      } else {
        throw new Error('Failed to update lock status');
      }
    } catch (error) {
      toast.error('Failed to update lock status', { id: loadingToast });
    }
  };

  const handleResetStages = async () => {
    const loadingToast = toast.loading('Resetting all stages...');
    try {
      await Promise.all([
        fetch(`${API_URL}/stages/reset`, { method: 'POST' }),
        fetch(`${API_URL}/stages/reset-active`, { method: 'POST' })
      ]);
      
      // Récupère les stages mis à jour
      const response = await fetch(`${API_URL}/stages`);
      const data = await response.json();
      
      if (data.data) {
        // Trouve le dernier stage
        const lastStage = data.data[data.data.length - 1];
        
        // Active le dernier stage
        if (lastStage) {
          await fetch(`${API_URL}/stages/${lastStage.id}/activate`, {
            method: 'PATCH'
          });
        }
        
        const finalResponse = await fetch(`${API_URL}/stages`);
        const finalData = await finalResponse.json();
        setStages(finalData.data);
        toast.success('All stages have been reset', { id: loadingToast });
      }
    } catch (error) {
      toast.error('Failed to reset stages', { id: loadingToast });
    } finally {
      setIsResetDialogOpen(false);
    }
  };

  const handleDeleteStage = async (id: number) => {
    if (stages.length <= 3) {
      toast.error('Cannot delete stage: Minimum 3 stages required');
      return;
    }

    const loadingToast = toast.loading('Deleting stage...');
    try {
      const response = await fetch(`${API_URL}/stages/${id}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        const data = await response.json();
        const updatedStages = stages.filter(stage => stage.id !== id);
        setStages(updatedStages);
        toast.success('Stage deleted successfully', { id: loadingToast });
      } else {
        throw new Error('Failed to delete stage');
      }
    } catch (error) {
      toast.error('Failed to delete stage', { id: loadingToast });
    }
  };

  const handleAddStage = async (data: { prize: number; minimum_score: number }) => {


    const loadingToast = toast.loading('Adding new stage...');
    try {
      const response = await fetch(`${API_URL}/stages`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const newStage = await response.json();
        const updatedStages = [...stages, newStage.data];
        setStages(updatedStages);
        toast.success('Stage added successfully', { id: loadingToast });
      } else {
        throw new Error('Failed to add stage');
      }
    } catch (error) {
      toast.error('Failed to add stage', { id: loadingToast });
    } finally {
      setIsAddStageDialogOpen(false);
    }
  };

  const fetchExtraLifeStatus = async () => {
    try {
      const response = await fetch(`${API_URL}/extra-life`);
      const data = await response.json();
      setExtraLifeStatus(data.data.is_active);
    } catch (error) {
      console.error('Failed to fetch Extra Life status:', error);
      toast.error('Failed to fetch Extra Life status');
    }
  };

  useEffect(() => {
    fetchExtraLifeStatus();
  }, []);

  const toggleExtraLife = async () => {
    const loadingToast = toast.loading(`${extraLifeStatus ? 'Disabling' : 'Enabling'} Extra Life...`);
    try {
      const response = await fetch(`${API_URL}/extra-life/toggle`, {
        method: 'PATCH',
      });

      if (response.ok) {
        const data = await response.json();
        setExtraLifeStatus(data.data.is_active);
        toast.success(`Extra Life ${extraLifeStatus ? 'disabled' : 'enabled'} successfully`, { id: loadingToast });
      } else {
        throw new Error('Failed to toggle Extra Life');
      }
    } catch (error) {
      console.error('Error toggling Extra Life:', error);
      toast.error('Failed to toggle Extra Life', { id: loadingToast });
    }
  };

  const resetExtraLife = async () => {
    const loadingToast = toast.loading('Resetting Extra Life status...');
    try {
      const response = await fetch(`${API_URL}/extra-life/reset`, {
        method: 'POST',
      });

      if (response.ok) {
        await fetchExtraLifeStatus();
        toast.success('Extra Life reset successfully', { id: loadingToast });
      } else {
        throw new Error('Failed to reset Extra Life');
      }
    } catch (error) {
      toast.error('Failed to reset Extra Life', { id: loadingToast });
    }
  };

  if (loading) {
    return (
      <div className="p-6 bg-[#1A1C23] rounded-xl shadow-lg">
        <div className="animate-pulse">
          <div className="h-8 bg-gray-700 rounded w-1/3 mb-8"></div>
          <div className="space-y-4">
            {[1, 2, 3].map((i) => (
              <div key={i} className="h-20 bg-gray-700 rounded"></div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-6 bg-[#1A1C23] rounded-xl shadow-lg">
    <Toaster position="top-right" toastOptions={{
      style: {
        background: '#333',
        color: '#fff',
      },
      duration: 3000,
    }} />



    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      className="mb-8 p-4 rounded-lg bg-[#252A34] border-l-4 border-red-500"
    >
      <div className="flex items-center justify-between">
        
        <div className="flex items-center">
          <Heart 
            className={`mr-3 ${extraLifeStatus ? 'text-red-500' : 'text-gray-500'}`} 
            size={24}
            fill={extraLifeStatus ? 'currentColor' : 'none'}
          />
          <div>
            <h2 className="text-white font-semibold text-lg">Extra Life</h2>
            <p className="text-gray-400 text-sm">Give players an additional chance to continue</p>
          </div>
        </div>
        
        <div className="flex items-center space-x-4">
 
          <button
            onClick={toggleExtraLife}
            className={`flex items-center px-4 py-2 rounded transition-colors ${
              extraLifeStatus 
                ? 'bg-red-500 hover:bg-red-600' 
                : 'bg-green-500 hover:bg-green-600'
            } text-white font-medium`}
          >
            <Power className="mr-2" size={18} />
            {extraLifeStatus ? 'Disable' : 'Enable'}
          </button>
        </div>
      </div>
    </motion.div>
    <ExtraLifeHideControl />
    <ConfettiControl />
      <Dialog 
        isOpen={isResetDialogOpen}
        onClose={() => setIsResetDialogOpen(false)}
        onConfirm={handleResetStages}
        title="Reset All Stages"
        message="Are you sure you want to reset all stages? This will reset both active states and unlock statuses. This action cannot be undone."
      />

      <NewStageDialog
        isOpen={isAddStageDialogOpen}
        onClose={() => setIsAddStageDialogOpen(false)}
        onConfirm={handleAddStage}
        title="Add New Stage"
      />

<DeleteDialog
        isOpen={stageToDelete !== null}
        onClose={() => setStageToDelete(null)}
        onConfirm={() => {
          if (stageToDelete !== null) {
            handleDeleteStage(stageToDelete);
            setStageToDelete(null);
          }
        }}
      />
      <div className="flex justify-between items-center mb-8">
      
        <h1 className="text-3xl font-bold text-white flex items-center">
          <Settings className="mr-3 text-[#611BE2]" size={32} />
          Prize Stage Settings
        </h1>
        <div className="flex space-x-4">
          <button
            onClick={() => setIsAddStageDialogOpen(true)}
            className="flex items-center px-4 py-2 bg-[#611BE2] text-white rounded hover:bg-[#8B5CF6] transition-colors"
            title={"Add new stage"}
          >
            <Plus className="mr-2" size={18} />
            Add Stage
          </button>
          <button
            onClick={() => setIsResetDialogOpen(true)}
            className="flex items-center px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-colors"
          >
            <RotateCcw className="mr-2" size={18} />
            Reset All Stages
          </button>
        </div>
      </div>

      <div className="space-y-4">
        <AnimatePresence>
          {stages.map((stage) => (
            <motion.div
              key={stage.id}
              layout
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className={`p-4 rounded-lg bg-[#252A34] border-l-4 ${
                stage.is_active ? 'border-[#611BE2]' :
                stage.is_unlocked ? 'border-green-500' : 'border-gray-600'
              } transition-all duration-300 ease-in-out`}
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <Trophy 
                    className={`mr-3 ${!stage.is_unlocked ? 'text-gray-500' : 'text-yellow-500'}`} 
                    size={24} 
                  />
                  <span className="text-white font-semibold">Stage {stage.position}</span>
          
                </div>
                
                <div className="flex items-center space-x-4">
                  {editingStage === stage.id ? (
                    <motion.div 
                      className="flex items-center"
                      initial={{ opacity: 0, scale: 0.8 }}
                      animate={{ opacity: 1, scale: 1 }}
                    >
                      <input
                        type="number"
                        value={editingValue}
                        onChange={(e) => setEditingValue(e.target.value)}
                        className="w-20 bg-[#1A1C23] text-white border border-[#611BE2] rounded px-2 py-1 mr-2"
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            handlePrizeChange(stage.id);
                          }
                        }}
                      />
                      <button 
                        onClick={() => handlePrizeChange(stage.id)}
                        className="text-white hover:text-[#611BE2] transition-colors"
                      >
                        <Save size={20} />
                      </button>
                    </motion.div>
                  ) : (
                    <motion.div 
                      className="flex items-center"
                      initial={{ opacity: 0, scale: 0.8 }}
                      animate={{ opacity: 1, scale: 1 }}
                    >
                      <span className="text-white mr-4">{stage.prize}€</span>
                      <button 
                        onClick={() => {
                          setEditingStage(stage.id);
                          setEditingValue(stage.prize.toString());
                        }}
                        className="text-white hover:text-[#611BE2] transition-colors"
                      >
                        <Edit2 size={20} />
                      </button>
                    </motion.div>
                  )}
                  
                  <button
                    onClick={() => toggleStageLock(stage.id, stage.is_unlocked)}
                    className={`p-2 rounded hover:bg-gray-700 transition-colors`}
                    title={stage.is_unlocked ? 'Lock stage' : 'Unlock stage'}
                  >
                    {stage.is_unlocked ? 
                      <Unlock size={20} className="text-green-500" /> :
                      <Lock size={20} className="text-gray-500" />
                    }
                  </button>

                  <button
                    onClick={() => handleStageActivation(stage.id, !stage.is_active)}
                    className={`px-3 py-1 rounded ${
                      stage.is_active
                        ? 'bg-[#611BE2] text-white'
                        : 'bg-gray-700 text-gray-300'
                    } hover:bg-[#8B5CF6] transition-colors`}
                  >
                    {stage.is_active ? 'Active' : 'Inactive'}
                  </button>

                  <button
                    onClick={() => setStageToDelete(stage.id)}
                    className="text-red-500 hover:text-red-400 p-2 rounded hover:bg-gray-700 transition-colors"
                    disabled={stages.length <= 3}
                    title={stages.length <= 3 ? "Minimum 3 stages required" : "Delete stage"}
                  >
                    <Trash2 size={20} />
                  </button>
                </div>
              </div>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
      
    </div>
  );
};

export default PrizeSettings;
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ExternalLink, Link as LinkIcon, Copy, CheckCheck } from 'lucide-react';
import { useState } from 'react';

interface UsefulLink {
  title: string;
  description: string;
  url: string;
  category: 'All' | 'Draw' | 'Stages' | 'Boxes' | 'Promo';
  icon?: React.ReactNode;
}

const UsefulLinks: React.FC = () => {
  const [copiedLink, setCopiedLink] = useState<string | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  const links: UsefulLink[] = [
    {
      title: "Draw Animation & Card",
      description: "Route for the Draw Overlay",
      url: "https://front-empire.selaris.app/tirage",
      category: "Draw",
      icon: <LinkIcon className="text-blue-500" />
    },
    {
      title: "Boxes & Mode Selector",
      description: "Boxes & Mode Selector Overlay",
      url: "https://front-empire.selaris.app/lootbox",
      category: "Boxes",
      icon: <LinkIcon className="text-purple-500" />
    },
    {
      title: "Stage & Extra Life Overlay",
      description: "Stage Overlay and Extra Life Component",
      url: "https://front-empire.selaris.app/leaderboard",
      category: "Stages",
      icon: <LinkIcon className="text-green-500" />
    },
    {
      title: "Promo Code",
      description: "For OBS Browser Source, add this custom CSS:\nbody { margin: 0 !important; padding: 0 !important; background-color: rgba(0, 0, 0, 0) !important; -webkit-mask-image: -webkit-radial-gradient(white, black) !important; } \n#root, .fixed, div { -webkit-mask-image: -webkit-radial-gradient(white, black) !important; }",
      url: "https://front-empire.selaris.app/promocode",
      category: "Promo",
      icon: <LinkIcon className="text-yellow-500" />
    }
];
  const categories = Array.from(new Set(links.map(link => link.category)));

  const copyToClipboard = async (url: string) => {
    try {
      await navigator.clipboard.writeText(url);
      setCopiedLink(url);
      setTimeout(() => setCopiedLink(null), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const filteredLinks = selectedCategory 
    ? links.filter(link => link.category === selectedCategory)
    : links;

  return (
    <motion.div
      className="p-8 bg-gradient-to-br from-[#1A1C23] to-[#252A34] rounded-2xl shadow-2xl"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="flex items-center justify-between mb-8">
        <h2 className="text-2xl font-bold text-white flex items-center">
          <ExternalLink className="mr-3 text-[#611BE2]" size={28} />
          Useful Links
        </h2>
        <div className="flex space-x-2">
          <motion.button
            onClick={() => setSelectedCategory(null)}
            className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
              !selectedCategory 
                ? 'bg-[#611BE2] text-white' 
                : 'bg-[#2C3038] text-gray-400 hover:bg-[#3D4149]'
            }`}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            All
          </motion.button>
          {categories.map((category) => (
            <motion.button
              key={category}
              onClick={() => setSelectedCategory(category)}
              className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                selectedCategory === category 
                  ? 'bg-[#611BE2] text-white' 
                  : 'bg-[#2C3038] text-gray-400 hover:bg-[#3D4149]'
              }`}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              {category}
            </motion.button>
          ))}
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <AnimatePresence mode="popLayout">
          {filteredLinks.map((link) => (
            <motion.div
              key={link.url}
              layout
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.2 }}
              className="group bg-[#2C3038] p-6 rounded-xl border-2 border-[#3D4149] hover:border-[#611BE2] transition-colors relative overflow-hidden"
            >
              <motion.div
                className="absolute inset-0 bg-gradient-to-r from-[#611BE2]/10 to-transparent opacity-0 group-hover:opacity-100 transition-opacity"
                initial={false}
              />
              
              <div className="relative z-10">
                <div className="flex items-start justify-between">
                  <div>
                    <h3 className="text-lg font-semibold text-white mb-2 flex items-center">
                      {link.icon}
                      <span className="ml-2">{link.title}</span>
                    </h3>
                    <p className="text-gray-400 text-sm mb-4 whitespace-pre-line">{link.description}</p>
                  </div>
                  <motion.button
                    onClick={() => copyToClipboard(link.url)}
                    className="p-2 hover:bg-[#611BE2]/20 rounded-lg transition-colors"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                  >
                    {copiedLink === link.url ? (
                      <CheckCheck className="text-green-500" size={20} />
                    ) : (
                      <Copy className="text-gray-400" size={20} />
                    )}
                  </motion.button>
                </div>
                <div className="flex items-center space-x-2">
                  <span className="text-sm text-gray-500 font-mono">{link.url}</span>
                  <motion.a
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="p-1 hover:bg-[#611BE2]/20 rounded-lg transition-colors"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                  >
                    <ExternalLink size={16} className="text-[#611BE2]" />
                  </motion.a>
                </div>
              </div>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    </motion.div>
  );
};

export default UsefulLinks;
// SavedPromoCodesCard.tsx
import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Save, Star, Copy, Edit, Trash2, Plus, Search, Wallet, Box } from 'lucide-react';
import { Card, CardHeader, CardTitle, CardContent } from './ui/card';
import { SavePromoDialog } from './SavedPromoDialog';
import { ConfirmDialog } from './ConfirmDialog';

export type PromoCodeType = 'Deposit Bonus' | 'Free Box' | 'Balance';


interface SavedPromoCode {
  id: number;
  name: string;
  code: string;
  value: number;
  type: PromoCodeType;
  requirements: string;
  usage: number;
  boxSlug: string | null;
  createdAt: Date;
}

interface SavedPromoCodesCardProps {
  onUseTemplate: (code: SavedPromoCode) => void;
}

const SavedPromoCodesCard: React.FC<SavedPromoCodesCardProps> = ({ onUseTemplate }) => {
  const [savedCodes, setSavedCodes] = useState<SavedPromoCode[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [editingCode, setEditingCode] = useState<SavedPromoCode | null>(null);
  const [searchTerm, setSearchTerm] = useState('');


  const [confirmDelete, setConfirmDelete] = useState<{ show: boolean; id: number | null }>({
    show: false,
    id: null
  });

  const fetchSavedCodes = async () => {
    try {
      setIsLoading(true);
      const response = await fetch('https://empire-back.selaris.app/api/saved-promo-codes');
      const data = await response.json();
      if (data.status === 'success') {
        setSavedCodes(data.data);
      }
    } catch (error) {
      console.error('Error fetching saved codes:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSavedCodes();
  }, []);

  const handleSave = async (data: Partial<SavedPromoCode>) => {
    try {
      const url = editingCode
        ? `https://empire-back.selaris.app/api/saved-promo-codes/${editingCode.id}`
        : 'https://empire-back.selaris.app/api/saved-promo-codes';
      
      const response = await fetch(url, {
        method: editingCode ? 'PUT' : 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        await fetchSavedCodes();
        setIsDialogOpen(false);
        setEditingCode(null);
      }
    } catch (error) {
      console.error('Error saving code:', error);
    }
  };

  const handleDelete = async (id: number) => {
    try {
      await fetch(`https://empire-back.selaris.app/api/saved-promo-codes/${id}`, {
        method: 'DELETE',
      });
      await fetchSavedCodes();
    } catch (error) {
      console.error('Error deleting code:', error);
    }
  };

  const filteredCodes = savedCodes.filter(code =>
    code.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    code.code.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Card>
      <CardHeader className="bg-gradient-to-r from-[#611BE2]/10 to-transparent">
        <CardTitle>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <Save className="mr-3 text-[#611BE2]" size={24} />
              Saved Promo Codes
            </div>
            <motion.button
              onClick={() => {
                setEditingCode(null);
                setIsDialogOpen(true);
              }}
              className="flex items-center gap-2 px-3 py-1.5 bg-[#611BE2]/10 text-[#611BE2] rounded-lg hover:bg-[#611BE2]/20"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <Plus size={18} />
              <span>New Template</span>
            </motion.button>
          </div>
        </CardTitle>
      </CardHeader>
  
      <CardContent>
        <div className="space-y-4">
          <div className="relative">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full bg-[#2C3038] text-white border-2 border-[#3D4149] rounded-lg py-2 pl-10 pr-4 focus:outline-none focus:border-[#611BE2]"
              placeholder="Search saved codes..."
            />
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#611BE2]" size={18} />
          </div>
  
          <div className="space-y-3 max-h-[400px] overflow-y-auto custom-scrollbar">
            <AnimatePresence>
              {filteredCodes.map((code) => (
                <motion.div
                  key={code.id}
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 20 }}
                  className="bg-[#2C3038] rounded-lg p-4 border-2 border-[#3D4149] hover:border-[#611BE2]/50 transition-colors group"
                >
                  <div className="flex items-start justify-between">
                    <div>
                      <div className="flex items-center gap-2">
                        <Star className="text-[#611BE2]" size={18} />
                        <h4 className="font-medium text-white">{code.code}</h4>
                      </div>
                      <div className="mt-2 space-y-1">
  <div className="flex items-center gap-2 text-sm flex-wrap">

    <div className="flex items-center bg-[#1A1C23] px-2 py-1 rounded">
      {code.type === 'Deposit Bonus' && (
        <>
          <span className="mr-2 text-[#611BE2]">%</span>
          <span className="text-gray-400">{code.value}% Bonus</span>
        </>
      )}
      {code.type === 'Free Box' && (
        <>
          <Box className="mr-2 text-[#611BE2]" size={14} />
          <span className="text-gray-400">Free Box</span>
          {code.boxSlug && (
            <span className="ml-1 text-gray-400">({code.boxSlug})</span>
          )}
        </>
      )}
      {code.type === 'Balance' && (
        <>
          <Wallet className="mr-2 text-[#611BE2]" size={14} />
          <span className="text-gray-400">{code.value}€ Balance</span>
        </>
      )}
    </div>
  </div>
  
  <div className="flex flex-wrap gap-2 mt-2">
    {code.requirements && (
      <div className="text-sm bg-purple-500/10 text-purple-500 px-2 py-0.5 rounded-full">
        {code.requirements}
      </div>
    )}
    {code.usage > 0 && (
      <div className="text-sm bg-[#611BE2]/10 text-[#FFF] px-2 py-0.5 rounded-full">
        Max usage: {code.usage}
      </div>
    )}
  </div>
</div>
                    </div>
                    <div className="flex items-center gap-2">
                      <motion.button
                        onClick={() => onUseTemplate(code)}
                        className="p-2 hover:bg-[#611BE2]/10 rounded-lg text-[#611BE2]"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                      >
                        <Copy size={18} />
                      </motion.button>
                      <motion.button
                        onClick={() => {
                          setEditingCode(code);
                          setIsDialogOpen(true);
                        }}
                        className="p-2 hover:bg-[#611BE2]/10 rounded-lg text-[#611BE2]"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                      >
                        <Edit size={18} />
                      </motion.button>
                      <motion.button
                        onClick={() => setConfirmDelete({ show: true, id: code.id })}
                        className="p-2 hover:bg-red-500/10 rounded-lg text-red-500"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                      >
                        <Trash2 size={18} />
                      </motion.button>
                    </div>
                  </div>
                </motion.div>
              ))}
            </AnimatePresence>
  
            {isLoading && (
              <div className="flex justify-center items-center py-8">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#611BE2]"></div>
              </div>
            )}
  
            {!isLoading && filteredCodes.length === 0 && (
              <div className="text-center py-8">
                <Save className="w-12 h-12 text-[#611BE2]/30 mx-auto mb-4" />
                <p className="text-gray-400">
                  {searchTerm ? 'No saved codes match your search' : 'No saved codes yet'}
                </p>
              </div>
            )}
          </div>
        </div>
      </CardContent>
  
      <AnimatePresence>
        {isDialogOpen && (
          <SavePromoDialog
            isOpen={isDialogOpen}
            onClose={() => {
              setIsDialogOpen(false);
              setEditingCode(null);
            }}
            onSave={handleSave}
            initialData={editingCode || undefined}
          />
        )}
      </AnimatePresence>
  
      <AnimatePresence>
        {confirmDelete.show && (
          <ConfirmDialog
            isOpen={confirmDelete.show}
            onClose={() => setConfirmDelete({ show: false, id: null })}
            onConfirm={() => {
              if (confirmDelete.id) handleDelete(confirmDelete.id);
            }}
            title="Delete Saved Code"
            message="Are you sure you want to delete this saved code? This action cannot be undone."
          />
        )}
      </AnimatePresence>
    </Card>
  );
};

export default SavedPromoCodesCard;
// SavePromoDialog.tsx
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { v4 as uuidv4 } from 'uuid';
import { Search } from 'lucide-react';

export type PromoCodeType = 'Balance' | 'Deposit Bonus' | 'Free Box';

interface SavedPromoCode {
  id: number;
  name: string;
  code: string;
  value: number;
  type: PromoCodeType;
  requirements: string;
  usage: number;
  boxSlug: string | null;
  createdAt: Date;
}

interface SavePromoDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (data: Partial<SavedPromoCode>) => Promise<void>;
  initialData?: Partial<SavedPromoCode>;
}

interface Box {
    name: string;
    slug: string;
    image: string;
    price: number;
    categories: { name: string; slug: string; type: string; }[];
  }
  
  export const SavePromoDialog: React.FC<SavePromoDialogProps> = ({ 
    isOpen, 
    onClose, 
    onSave, 
    initialData 
  }) => {
    const [formData, setFormData] = useState<Partial<SavedPromoCode>>(initialData || {
      name: uuidv4(),
      code: '',
      value: 0,
      type: 'Balance',
      requirements: '',
      usage: 0,
      boxSlug: null
    });
  
    // States for box selection
    const [showBoxSearch, setShowBoxSearch] = useState(false);
    const [boxes, setBoxes] = useState<Box[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [selectedBox, setSelectedBox] = useState<Box | null>(null);
  
    const fetchBoxes = async () => {
      try {
        setIsLoading(true);
        const response = await fetch('https://api.empiredrop.com/api/v1/boxes/search', {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            brands: [],
            categories: [],
            order: 'CHEAPEST',
            price_max: 500000,
            price_min: 0,
            tags: []
          })
        });
  
        const data = await response.json();
        const formattedBoxes = data.map((box: any) => ({
          name: box.name,
          slug: box.slug,
          image: box.image.startsWith('/') ? `https://cdn.empiredrop.com${box.image}` : `https://cdn.empiredrop.com/${box.image}`,
          price: box.price,
          categories: box.categories || []
        }));
        setBoxes(formattedBoxes);
      } catch (error) {
        console.error('Error fetching boxes:', error);
      } finally {
        setIsLoading(false);
      }
    };
  
    useEffect(() => {
      if (formData.type === 'Free Box') {
        fetchBoxes();
      }
    }, [formData.type]);
  
    const handleSubmit = async () => {
      try {
        await onSave(formData);
      } catch (error) {
        console.error('Error saving template:', error);
      }
    };
  
    const filteredBoxes = boxes.filter(box =>
      box.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  
    if (!isOpen) return null;

    return (
        <motion.div
          className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={onClose}
        >
          <motion.div
            className="bg-[#1A1C23] rounded-xl p-6 w-[400px] max-h-[90vh] overflow-y-auto"
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            onClick={e => e.stopPropagation()}
          >
            <form 
              onSubmit={async (e) => {
                e.preventDefault();
                if (!formData.code || 
                    (!formData.value && formData.type !== 'Free Box') ||
                    (formData.type === 'Free Box' && !formData.boxSlug)) {
                  return;
                }
                await handleSubmit();
              }}
            >
              <h3 className="text-xl font-bold text-white mb-6">
                {initialData ? 'Edit Template' : 'Save New Template'}
              </h3>
      
              <div className="space-y-4">
       
      
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="text-sm text-gray-400 mb-1 block">Code Name</label>
                    <input
                      type="text"
                      placeholder="Promo code"
                      value={formData.code}
                      onChange={e => setFormData({ ...formData, code: e.target.value })}
                      className="w-full bg-[#2C3038] text-white border-2 border-[#3D4149] rounded-lg py-2 px-4 focus:outline-none focus:border-[#611BE2]"
                    />
                  </div>
                  <div>
                    <label className="text-sm text-gray-400 mb-1 block">Value in €</label>
                    <input
                      type="number"
                      placeholder="Value"
                      value={formData.value}
                      onChange={e => setFormData({ ...formData, value: Number(e.target.value) })}
                      className="w-full bg-[#2C3038] text-white border-2 border-[#3D4149] rounded-lg py-2 px-4 focus:outline-none focus:border-[#611BE2]"
                    />
                  </div>
                </div>
      
                <div>
                  <label className="text-sm text-gray-400 mb-1 block">Type</label>
                  <select
                    value={formData.type}
                    onChange={e => setFormData({ ...formData, type: e.target.value as PromoCodeType })}
                    className="w-full bg-[#2C3038] text-white border-2 border-[#3D4149] rounded-lg py-2 px-4 focus:outline-none focus:border-[#611BE2]"
                  >
                    <option value="Balance">Tip Balance</option>
                    <option value="Deposit Bonus">Deposit Bonus %</option>
                    <option value="Free Box">Free Box</option>
                  </select>
                </div>
      
                <div>
                  <label className="text-sm text-gray-400 mb-1 block">Requirements (Optional)</label>
                  <input
                    type="text"
                    placeholder="Enter requirements"
                    value={formData.requirements}
                    onChange={e => setFormData({ ...formData, requirements: e.target.value })}
                    className="w-full bg-[#2C3038] text-white border-2 border-[#3D4149] rounded-lg py-2 px-4 focus:outline-none focus:border-[#611BE2]"
                  />
                </div>
      
                <div>
                  <label className="text-sm text-gray-400 mb-1 block">Usage Limit</label>
                  <input
                    type="number"
                    placeholder="Max number of uses"
                    value={formData.usage}
                    onChange={e => setFormData({ ...formData, usage: Number(e.target.value) })}
                    className="w-full bg-[#2C3038] text-white border-2 border-[#3D4149] rounded-lg py-2 px-4 focus:outline-none focus:border-[#611BE2]"
                  />
                </div>
      
                {formData.type === 'Free Box' && (
                  <div>
                    <label className="text-sm text-gray-400 mb-1 block">Box Selection</label>
                    {selectedBox ? (
                      <div className="flex items-center space-x-4 p-3 bg-[#2C3038] rounded-xl border-2 border-[#611BE2]">
                        <img 
                          src={selectedBox.image} 
                          alt={selectedBox.name} 
                          className="w-12 h-12 object-cover rounded-lg"
                        />
                        <div className="flex-1">
                          <div className="text-white font-medium">{selectedBox.name}</div>
                          <div className="text-sm text-gray-400">
                            {selectedBox.categories.map(cat => cat.name).join(', ')}
                          </div>
                        </div>
                        <button
                          type="button"
                          onClick={() => {
                            setSelectedBox(null);
                            setFormData(prev => ({ ...prev, boxSlug: null }));
                          }}
                          className="text-[#611BE2] hover:underline"
                        >
                          Change
                        </button>
                      </div>
                    ) : (
                      <div className="space-y-4">
                        <div className="relative">
                          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#611BE2]" size={18} />
                          <input
                            type="text"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="w-full bg-[#2C3038] text-white border-2 border-[#3D4149] rounded-lg py-2 pl-10 pr-4 focus:outline-none focus:border-[#611BE2]"
                            placeholder="Search boxes..."
                          />
                        </div>
      
                        <div className="max-h-[200px] overflow-y-auto custom-scrollbar space-y-2">
                          {isLoading ? (
                            <div className="flex justify-center items-center py-4">
                              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#611BE2]"></div>
                            </div>
                          ) : (
                            filteredBoxes.map((box) => (
                              <motion.div
                                key={box.slug}
                                className="flex items-center space-x-4 p-3 bg-[#2C3038] rounded-xl border border-[#3D4149] hover:border-[#611BE2] cursor-pointer transition-all"
                                onClick={() => {
                                  setSelectedBox(box);
                                  setFormData(prev => ({ ...prev, boxSlug: box.slug }));
                                }}
                                whileHover={{ scale: 1.02 }}
                                whileTap={{ scale: 0.98 }}
                              >
                                <img 
                                  src={box.image} 
                                  alt={box.name} 
                                  className="w-12 h-12 object-cover rounded-lg"
                                />
                                <div className="flex-1">
                                  <div className="text-white font-medium">{box.name}</div>
                                  <div className="text-sm text-gray-400">
                                    {box.categories.map(cat => cat.name).join(', ')}
                                  </div>
                                </div>
                                <div className="text-[#611BE2] font-bold">
                                  {(box.price / 100).toFixed(2)}€
                                </div>
                              </motion.div>
                            ))
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
      
                <div className="flex justify-end gap-3 mt-6">
                  <button
                    type="button"
                    onClick={onClose}
                    className="px-4 py-2 bg-[#2C3038] text-white rounded-lg hover:bg-[#3D4149] transition-colors"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={
                      !formData.code || 
                      (!formData.value && formData.type !== 'Free Box') ||
                      (formData.type === 'Free Box' && !formData.boxSlug)
                    }
                    className="px-4 py-2 bg-[#611BE2] text-white rounded-lg hover:bg-[#4F15B8] transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    {initialData ? 'Update' : 'Save'}
                  </button>
                </div>
              </div>
            </form>
          </motion.div>
        </motion.div>
      );
    };

export default SavePromoDialog;
import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, Mail, Lock, User, Check, Gift, ChevronRight } from 'lucide-react';
import axios from 'axios';
import { Toaster, toast } from 'react-hot-toast';

interface RegisterModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSwitchToLogin: () => void;
  }

const RegisterModal: React.FC<RegisterModalProps> = ({ isOpen, onClose, onSwitchToLogin }) => {
  const [step, setStep] = useState(1);
  const [displayName, setDisplayName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [promoCode, setPromoCode] = useState('');
  const [marketingConsent, setMarketingConsent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const modalVariants = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: { 
      opacity: 1, 
      scale: 1, 
      transition: { type: "spring", duration: 0.5, bounce: 0.3 }
    },
    exit: { 
      opacity: 0, 
      scale: 0.9, 
      transition: { duration: 0.2 }
    }
  };

  const slideVariants = {
    enter: (direction: number) => ({
      x: direction > 0 ? 1000 : -1000,
      opacity: 0
    }),
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1
    },
    exit: (direction: number) => ({
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0
    })
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.post('https://empire-back.selaris.app/api/auth/register', {
        username: displayName,
        email,
        password,
        promoCode: promoCode || "MyDropGG",
        marketingConsent,
        pfp: null
      });

      if (response.status === 201) {
        toast.custom((t) => (
          <motion.div
            initial={{ opacity: 0, y: -50, scale: 0.6 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8, transition: { duration: 0.2 } }}
            className="bg-gradient-to-r from-[#611BE2] to-[#8B5CF6] text-white px-6 py-4 rounded-lg shadow-lg flex items-center space-x-2"
          >
            <span className="text-2xl">🎉</span>
            <span className="font-semibold">Welcome to MyDrop.gg, {response.data.user.username}!</span>
          </motion.div>
        ), { duration: 4000, position: 'top-right' });
        
        onClose();
      } else {
        throw new Error('Registration failed');
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unexpected error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  const renderStep = () => {
    switch(step) {
      case 1:
        return (
          <motion.div
            key="step1"
            variants={slideVariants}
            initial="enter"
            animate="center"
            exit="exit"
            custom={1}
          >
            <h2 className="text-2xl font-bold text-white mb-6">Create Your Account</h2>
            <div className="space-y-4">
              <div>
                <label htmlFor="displayName" className="block text-sm font-medium text-gray-300 mb-1">
                  Display Name
                </label>
                <div className="relative">
                  <input
                    type="text"
                    id="displayName"
                    value={displayName}
                    onChange={(e) => setDisplayName(e.target.value)}
                    className="w-full bg-[#1A1C23] text-white border border-gray-600 rounded-lg py-3 px-4 pl-12 focus:outline-none focus:border-[#611BE2] transition-colors duration-200"
                    placeholder="Your display name"
                  />
                  <User className="absolute left-4 top-3.5 text-gray-400" size={18} />
                </div>
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-1">
                  Email Address
                </label>
                <div className="relative">
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full bg-[#1A1C23] text-white border border-gray-600 rounded-lg py-3 px-4 pl-12 focus:outline-none focus:border-[#611BE2] transition-colors duration-200"
                    placeholder="Your email address"
                  />
                  <Mail className="absolute left-4 top-3.5 text-gray-400" size={18} />
                </div>
              </div>
            </div>
            <button
              onClick={() => setStep(2)}
              className="mt-6 w-full bg-gradient-to-r from-[#611BE2] to-[#8B5CF6] hover:from-[#8B5CF6] hover:to-[#611BE2] text-white py-3 rounded-lg font-semibold transition duration-300 shadow-md flex items-center justify-center"
            >
              Next <ChevronRight size={20} className="ml-2" />
            </button>
          </motion.div>
        );
      case 2:
        return (
          <motion.div
            key="step2"
            variants={slideVariants}
            initial="enter"
            animate="center"
            exit="exit"
            custom={1}
          >
            <h2 className="text-2xl font-bold text-white mb-6">Secure Your Account</h2>
            <div className="space-y-4">
              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-300 mb-1">
                  Password
                </label>
                <div className="relative">
                  <input
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full bg-[#1A1C23] text-white border border-gray-600 rounded-lg py-3 px-4 pl-12 focus:outline-none focus:border-[#611BE2] transition-colors duration-200"
                    placeholder="Your secure password"
                  />
                  <Lock className="absolute left-4 top-3.5 text-gray-400" size={18} />
                </div>
              </div>
              <div>
                <label htmlFor="promoCode" className="block text-sm font-medium text-gray-300 mb-1">
                  Promo Code (Optional)
                </label>
                <div className="relative">
                  <input
                    type="text"
                    id="promoCode"
                    value={promoCode}
                    onChange={(e) => setPromoCode(e.target.value)}
                    className="w-full bg-[#1A1C23] text-white border border-gray-600 rounded-lg py-3 px-4 pl-12 focus:outline-none focus:border-[#611BE2] transition-colors duration-200"
                    placeholder="Enter promo code"
                  />
                  <Gift className="absolute left-4 top-3.5 text-gray-400" size={18} />
                </div>
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="marketingConsent"
                  checked={marketingConsent}
                  onChange={(e) => setMarketingConsent(e.target.checked)}
                  className="hidden"
                />
                <label
                  htmlFor="marketingConsent"
                  className={`flex items-center cursor-pointer text-sm text-gray-300 ${
                    marketingConsent ? 'text-[#611BE2]' : ''
                  }`}
                >
                  <span className={`w-5 h-5 inline-block mr-2 rounded border ${
                    marketingConsent ? 'bg-[#611BE2] border-[#611BE2]' : 'border-gray-400'
                  } flex-shrink-0 flex items-center justify-center`}>
                    {marketingConsent && <Check size={14} className="text-white" />}
                  </span>
                  Send me bonus and marketing emails
                </label>
              </div>
            </div>
            <div className="flex space-x-4 mt-6">
              <button
                onClick={() => setStep(1)}
                className="w-1/2 bg-[#1A1C23] text-white py-3 rounded-lg font-semibold transition duration-300 shadow-md flex items-center justify-center"
              >
                Back
              </button>
              <button
                onClick={handleSubmit}
                className="w-1/2 bg-gradient-to-r from-[#611BE2] to-[#8B5CF6] hover:from-[#8B5CF6] hover:to-[#611BE2] text-white py-3 rounded-lg font-semibold transition duration-300 shadow-md flex items-center justify-center"
                disabled={isLoading}
              >
                {isLoading ? (
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                ) : "Register"}
              </button>
            </div>
          </motion.div>
        );
    }
  };

  return (
    <>
      <Toaster />
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-75"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              className="bg-gradient-to-b from-[#0E1015] to-[#1A1C23] rounded-2xl shadow-2xl overflow-hidden max-w-md w-full"
              variants={modalVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
                            <motion.p 
            className="mt-6 text-center text-gray-400"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            >
            Already have an account?{' '}
            <button 
                onClick={onSwitchToLogin} 
                className="text-[#611BE2] hover:text-[#8B5CF6] transition-colors duration-200 font-semibold"
            >
                Log in
            </button>
            </motion.p>
              <div className="relative p-8">
                <button 
                  onClick={onClose} 
                  className="absolute top-4 right-4 text-gray-400 hover:text-white transition-colors duration-200"
                >
                  <X size={24} />
                </button>
                <div className="mb-8">
                  <div className="w-full bg-gray-600 h-1 rounded-full">
                    <motion.div 
                      className="h-full bg-gradient-to-r from-[#611BE2] to-[#8B5CF6] rounded-full"
                      initial={{ width: "50%" }}
                      animate={{ width: step === 1 ? "50%" : "100%" }}
                      transition={{ duration: 0.5 }}
                    />
                  </div>
                </div>
                <AnimatePresence mode="wait">
                  {renderStep()}
                </AnimatePresence>
                {error && (
                  <motion.div 
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 10 }}
                    className="mt-4 bg-red-500 bg-opacity-10 border border-red-500 text-red-500 px-4 py-3 rounded-lg" 
                    role="alert"
                  >
                    <strong className="font-bold">Error!</strong>
                    <span className="block sm:inline"> {error}</span>
                  </motion.div>
                )}
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default RegisterModal;
// AuthContext.tsx
import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import axios from 'axios';

interface AuthContextType {
  isLoggedIn: boolean;
  username: string;
  token: string | null;
  login: (token: string, username: string) => void;
  logout: () => void;
  checkAuth: () => Promise<void>;  // Garde le type Promise<void>
}

interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    return localStorage.getItem('token') !== null;
  });
  const [username, setUsername] = useState(() => {
    return localStorage.getItem('username') || '';
  });
  const [token, setToken] = useState<string | null>(() => {
    return localStorage.getItem('token');
  });

  const checkAuth = async (): Promise<void> => {  // Explicitement retourne Promise<void>
    const storedToken = localStorage.getItem('token');
    const storedUsername = localStorage.getItem('username');

    if (storedToken && storedUsername) {
      try {
        const response = await axios.get('https://empire-back.selaris.app/api/auth/verify', {
          headers: { Authorization: `Bearer ${storedToken}` }
        });

        if (response.status === 200) {
          setIsLoggedIn(true);
          setUsername(storedUsername);
          setToken(storedToken);
        } else {
          await logout();
        }
      } catch (error) {
        await logout();
      }
    }
  };

  const login = (newToken: string, newUsername: string) => {
    localStorage.setItem('token', newToken);
    localStorage.setItem('username', newUsername);
    setToken(newToken);
    setIsLoggedIn(true);
    setUsername(newUsername);
    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
  };

  const logout = async () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    setToken(null);
    setIsLoggedIn(false);
    setUsername('');
    delete axios.defaults.headers.common['Authorization'];
  };

  useEffect(() => {
    checkAuth();
    const interval = setInterval(() => {
      checkAuth();
    }, 5 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }
  }, [token]);

  return (
    <AuthContext.Provider value={{ 
      isLoggedIn, 
      username, 
      token,
      login, 
      logout,
      checkAuth
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, Mail, Lock, Eye, EyeOff, ChevronRight } from 'lucide-react';
import axios from 'axios';
import { Toaster, toast } from 'react-hot-toast';
import { useAuth } from './AuthContext';

interface LoginModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSwitchToRegister: () => void;
}

const LoginModal: React.FC<LoginModalProps> = ({ isOpen, onClose, onSwitchToRegister }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { login } = useAuth();

  const modalVariants = {
    hidden: { opacity: 0, y: 50, scale: 0.9 },
    visible: { 
      opacity: 1, 
      y: 0, 
      scale: 1, 
      transition: { 
        type: "spring", 
        stiffness: 300, 
        damping: 30 
      } 
    },
    exit: { 
      opacity: 0, 
      y: 50, 
      scale: 0.9, 
      transition: { 
        duration: 0.2 
      } 
    }
  };

  const overlayVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.3 } },
    exit: { opacity: 0, transition: { duration: 0.2 } }
  };

  const inputVariants = {
    focus: { scale: 1.02, transition: { duration: 0.2 } },
    blur: { scale: 1, transition: { duration: 0.2 } }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.post('https://empire-back.selaris.app/api/auth/login', {
        email,
        password
      });

      if (response.status === 200) {
        login(response.data.token, response.data.user.username);

        toast.custom((t) => (
          <motion.div
            initial={{ opacity: 0, y: -50, scale: 0.6 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8, transition: { duration: 0.2 } }}
            className="bg-gradient-to-r from-[#611BE2] to-[#8B5CF6] text-white px-6 py-4 rounded-lg shadow-lg flex items-center space-x-2"
          >
            <span className="text-2xl">👋</span>
            <span className="font-semibold">Welcome back, {response.data.user.username}!</span>
          </motion.div>
        ), { duration: 4000, position: 'top-right' });

        onClose();
      }
    } catch (error) {
      toast.error('Login failed. Please check your credentials.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Toaster />
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="fixed inset-0 z-50 flex items-center justify-center p-4"
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <motion.div
              className="absolute inset-0 bg-black bg-opacity-60 backdrop-blur-sm"
              variants={overlayVariants}
              onClick={onClose}
            />
            <motion.div
              className="bg-gradient-to-b from-[#0E1015] to-[#1A1C23] p-8 rounded-2xl shadow-2xl w-full max-w-md relative z-10 overflow-hidden"
              variants={modalVariants}
            >
              <button
                onClick={onClose}
                className="absolute top-4 right-4 text-gray-400 hover:text-white transition-colors duration-200"
              >
                <X size={24} />
              </button>
              <motion.div
                initial={{ x: 0 }}
                animate={{ x: 0 }}
                exit={{ x: -300 }}
                transition={{ type: "spring", stiffness: 300, damping: 30 }}
              >
                <h2 className="text-3xl font-bold text-white mb-6">Welcome Back</h2>
                <form onSubmit={handleSubmit} className="space-y-6">
                  <motion.div variants={inputVariants} whileFocus="focus" whileTap="focus">
                    <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-2">
                      Email
                    </label>
                    <div className="relative">
                      <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full bg-[#1A1C23] text-white border border-gray-600 rounded-lg py-3 px-4 pl-12 focus:outline-none focus:border-[#611BE2] transition-all duration-200"
                        placeholder="Enter your email"
                      />
                      <Mail className="absolute left-4 top-3.5 text-gray-400" size={20} />
                    </div>
                  </motion.div>
                  <motion.div variants={inputVariants} whileFocus="focus" whileTap="focus">
                    <label htmlFor="password" className="block text-sm font-medium text-gray-300 mb-2">
                      Password
                    </label>
                    <div className="relative">
                      <input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="w-full bg-[#1A1C23] text-white border border-gray-600 rounded-lg py-3 px-4 pl-12 pr-12 focus:outline-none focus:border-[#611BE2] transition-all duration-200"
                        placeholder="Enter your password"
                      />
                      <Lock className="absolute left-4 top-3.5 text-gray-400" size={20} />
                      <button
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                        className="absolute right-4 top-3.5 text-gray-400 hover:text-white transition-colors duration-200"
                      >
                        {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                      </button>
                    </div>
                  </motion.div>
                  <motion.button
                    type="submit"
                    className="w-full bg-gradient-to-r from-[#611BE2] to-[#8B5CF6] hover:from-[#8B5CF6] hover:to-[#611BE2] text-white py-3 rounded-lg font-semibold transition duration-300 shadow-md flex items-center justify-center"
                    disabled={isLoading}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    {isLoading ? (
                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    ) : (
                      <>
                        Log In
                        <ChevronRight size={20} className="ml-2" />
                      </>
                    )}
                  </motion.button>
                </form>
                <motion.p 
                  className="mt-6 text-center text-gray-400"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.2 }}
                >
                  Don't have an account?{' '}
                  <button 
                    onClick={onSwitchToRegister} 
                    className="text-[#611BE2] hover:text-[#8B5CF6] transition-colors duration-200 font-semibold"
                  >
                    Sign up
                  </button>
                </motion.p>
              </motion.div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default LoginModal;
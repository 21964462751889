import React, { useState } from 'react';
import { Wallet, LogIn, ChevronDown, UserPlus, Menu, User, Settings, LogOut } from 'lucide-react';
import LoginModal from './Authentication/LoginModal';
import RegisterModal from './Authentication/RegisterModal';
import { useAuth } from './Authentication/AuthContext';
import { useSpring, animated } from 'react-spring';

interface HeaderProps {
  toggleSidebar: () => void;
  isSidebarOpen: boolean;
  isMobile: boolean;
}

const Header: React.FC<HeaderProps> = ({ toggleSidebar, isSidebarOpen, isMobile }) => {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const { isLoggedIn, username, logout } = useAuth();

  const handleLogout = () => {
    logout();
    setIsProfileMenuOpen(false);
  };

  const openLoginModal = () => {
    setIsLoginModalOpen(true);
    setIsRegisterModalOpen(false);
  };

  const openRegisterModal = () => {
    setIsRegisterModalOpen(true);
    setIsLoginModalOpen(false);
  };

  return (
    <>
      <header className="bg-gradient-to-b from-[#0E1015] to-[#1A1C23] text-white py-4 px-4 sm:px-6 lg:px-8 flex justify-between items-center shadow-lg">
        <div className="flex items-center">
          {isMobile && (
            <button onClick={toggleSidebar} className="text-white mr-4 hover:text-[#8B5CF6] transition-colors duration-300">
              <Menu size={24} />
            </button>
          )}
          <img 
            src="/assets/images/big-empire-drop-logo.png" 
            alt="Empire Drop Logo" 
            className="h-8 mr-4"
          />
        </div>

        <div className="flex items-center space-x-3">
          {isLoggedIn ? (
            <>
              <div className="relative">
                <button 
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsProfileMenuOpen(!isProfileMenuOpen);
                  }}
                  className="flex items-center space-x-2 focus:outline-none"
                >
                  <span className="text-white font-medium hidden sm:inline">{username}</span>
                  <div className="w-10 h-10 rounded-full bg-[#1A1C23] flex items-center justify-center transition duration-300 transform hover:scale-110 hover:bg-[#611BE2]">
                    <User size={20} className="text-[#8B5CF6] group-hover:text-white" />
                  </div>
                </button>
                {isProfileMenuOpen && (
                  <div 
                    className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-[#1A1C23] ring-1 ring-black ring-opacity-5 z-50"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                      <a href="#" className="block px-4 py-2 text-sm text-gray-300 hover:bg-[#611BE2] hover:text-white transition-colors duration-200" role="menuitem">
                        <User size={18} className="inline mr-2" /> Profile
                      </a>
                      <a href="#" className="block px-4 py-2 text-sm text-gray-300 hover:bg-[#611BE2] hover:text-white transition-colors duration-200" role="menuitem">
                        <Settings size={18} className="inline mr-2" /> Settings
                      </a>
                      <button onClick={handleLogout} className="block w-full text-left px-4 py-2 text-sm text-gray-300 hover:bg-[#611BE2] hover:text-white transition-colors duration-200" role="menuitem">
                        <LogOut size={18} className="inline mr-2" /> Logout
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <button 
                className="bg-[#611BE2] text-white hover:bg-[#8B5CF6] px-4 sm:px-6 py-2 rounded-lg transition duration-300 flex items-center font-semibold"
                onClick={openRegisterModal}
              >
                <UserPlus size={18} className="mr-2" />
                <span className="hidden sm:inline">Register</span>
              </button>
              <button 
                className="bg-transparent border border-[#611BE2] text-[#8B5CF6] hover:bg-[#611BE2] hover:text-white px-4 sm:px-6 py-2 rounded-lg transition duration-300 flex items-center font-semibold"
                onClick={openLoginModal}
              >
                <LogIn size={18} className="mr-2" />
                <span className="hidden sm:inline">Login</span>
              </button>
            </>
          )}
        </div>
      </header>
      
      <LoginModal 
        isOpen={isLoginModalOpen} 
        onClose={() => setIsLoginModalOpen(false)}
        onSwitchToRegister={() => {
          setIsLoginModalOpen(false);
          setIsRegisterModalOpen(true);
        }}
      />
      
      <RegisterModal 
        isOpen={isRegisterModalOpen} 
        onClose={() => setIsRegisterModalOpen(false)}
        onSwitchToLogin={() => {
          setIsRegisterModalOpen(false);
          setIsLoginModalOpen(true);
        }}
      />
    </>
  );
};

export default Header;
import React, { useEffect, useRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { LogIn, UserPlus, Mail, Lock, Eye, EyeOff, ArrowRight, Loader2, AlertCircle, MailIcon, Check, AlertOctagon, Clock } from 'lucide-react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import gsap from 'gsap';
import { useAuth } from './AuthContext';


interface LoginFormData {
  email: string;
  password: string;
}

interface Error {
    message: string;
    isVisible: boolean;
    type: 'error' | 'warning' | 'info';
    code?: string;
  }

enum LoginErrorCodes {
    MISSING_FIELDS = 'MISSING_FIELDS',
    INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
    ACCOUNT_NOT_CONFIRMED = 'ACCOUNT_NOT_CONFIRMED',
    SERVER_ERROR = 'SERVER_ERROR',
    SUCCESS = 'SUCCESS'
  }

interface RegisterFormData {
  username: string;
  email: string;
  password: string;
}

export const LoginPage: React.FC = () => {
    const formRef = useRef<HTMLDivElement>(null);
    const logoRef = useRef<HTMLImageElement>(null);
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Error>({ 
      message: '', 
      isVisible: false,
      type: 'error',
      code: ''
    });
    
    const { login, isLoggedIn } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
  
    // Rediriger si déjà connecté
    useEffect(() => {
      const checkInitialAuth = async () => {
        try {
          const token = localStorage.getItem('token');
          if (token) {
            const response = await axios.get('https://empire-back.selaris.app/api/auth/verify', {
              headers: { Authorization: `Bearer ${token}` }
            });
            
            if (response.status === 200) {
              const username = localStorage.getItem('username');
              if (username) {
                login(token, username);
                navigate('/');
              }
            }
          }
        } catch (error) {
          localStorage.removeItem('token');
          localStorage.removeItem('username');
        }
      };
  
      checkInitialAuth();
    }, []);
  
    const [formData, setFormData] = useState<LoginFormData>({
      email: '',
      password: ''
    });
  
    // Animations GSAP...
    useEffect(() => {
      const ctx = gsap.context(() => {
        gsap.from(logoRef.current, {
          duration: 1.2,
          y: -100,
          opacity: 0,
          ease: 'elastic.out(1, 0.5)',
          delay: 0.2
        });
  
        gsap.from('.auth-card', {
          duration: 1,
          y: 100,
          opacity: 0,
          ease: 'power4.out',
          delay: 0.5
        });
  
        gsap.from('.form-element', {
          duration: 0.8,
          y: 20,
          opacity: 0,
          stagger: 0.2,
          ease: 'power3.out',
          delay: 1
        });
      }, formRef);
  
      return () => ctx.revert();
    }, []);
  
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setIsLoading(true);
      setError({ message: '', isVisible: false, type: 'error', code: '' });
    
      try {
        const response = await axios.post<{
          token: string;
          user: { username: string }
        }>('https://empire-back.selaris.app/api/auth/login', {
          email: formData.email,
          password: formData.password
        });
    
        if (response.status === 200 && response.data.token) {
          // Sauvegarde explicite dans localStorage
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('username', response.data.user.username);
          
          // Login via context
          login(response.data.token, response.data.user.username);
          
          // Navigation
          const from = (location.state as any)?.from?.pathname || '/';
          navigate(from, { replace: true });
        }
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          const { data, status } = err.response;
          
          switch (data.code) {
            case LoginErrorCodes.ACCOUNT_NOT_CONFIRMED:
              setError({
                message: 'Your account is awaiting approval. Our team will review it shortly.',
                isVisible: true,
                type: 'warning',
                code: data.code
              });
              break;
  
            case LoginErrorCodes.INVALID_CREDENTIALS:
              setError({
                message: 'Invalid email or password. Please try again.',
                isVisible: true,
                type: 'error',
                code: data.code
              });
              break;
  
            case LoginErrorCodes.MISSING_FIELDS:
              setError({
                message: 'Please fill in all required fields.',
                isVisible: true,
                type: 'error',
                code: data.code
              });
              break;
  
            case LoginErrorCodes.SERVER_ERROR:
              setError({
                message: 'An unexpected error occurred. Please try again later.',
                isVisible: true,
                type: 'error',
                code: data.code
              });
              break;
  
            default:
              setError({
                message: data.message || 'An unexpected error occurred',
                isVisible: true,
                type: 'error',
                code: 'UNKNOWN_ERROR'
              });
          }
  
          // Animation de l'erreur
          gsap.from('.error-message', {
            y: -20,
            opacity: 0,
            duration: 0.3,
            ease: 'power2.out'
          });
        }
      } finally {
        setIsLoading(false);
      }
    };
  
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    };
  
    const isFormValid = (): boolean => {
      return formData.email.includes('@') && formData.password.length >= 6;
    };
  
    const getErrorIcon = () => {
      switch (error.type) {
        case 'warning':
          return <AlertOctagon className="text-yellow-500" size={20} />;
        case 'info':
          return <Clock className="text-blue-500" size={20} />;
        default:
          return <AlertCircle className="text-red-500" size={20} />;
      }
    };
  
    const getErrorStyles = () => {
      switch (error.type) {
        case 'warning':
          return 'bg-yellow-500/10 border-yellow-500/50 text-yellow-500';
        case 'info':
          return 'bg-blue-500/10 border-blue-500/50 text-blue-500';
        default:
          return 'bg-red-500/10 border-red-500/50 text-red-500';
      }
    };
  
    return (
      <div className="min-h-screen bg-gradient-to-b from-[#0E1015] to-[#1A1C23] flex flex-col items-center justify-center p-4">
        <div ref={formRef} className="w-full max-w-md">
          <div className="flex flex-col items-center mb-8">
            <motion.img
              ref={logoRef}
              src="/assets/images/big-empire-drop-logo.png"
              alt="Empire Drop Logo"
              className="h-16 mb-6"
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            />
          </div>
  
          <motion.div 
            className="auth-card bg-gradient-to-br from-[#1A1C23] to-[#252A34] p-8 rounded-2xl shadow-2xl"
            initial={false}
          >
            <div className="text-center mb-8">
              <h2 className="text-3xl font-bold text-white mb-2">Welcome Back</h2>
              <p className="text-gray-400">Sign in to continue your journey</p>
            </div>
  
            {error.isVisible && (
              <motion.div 
                className={`error-message border rounded-lg p-4 mb-6 flex items-center space-x-2 ${getErrorStyles()}`}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
              >
                {getErrorIcon()}
                <p className="text-sm">{error.message}</p>
              </motion.div>
            )}
  
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="form-element">
                <div className="relative">
                  <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    className="w-full bg-[#0E1015] text-white rounded-lg pl-12 pr-4 py-3 focus:outline-none focus:ring-2 focus:ring-[#611BE2] transition-all duration-300"
                    value={formData.email}
                    onChange={handleInputChange}
                    disabled={isLoading}
                  />
                </div>
              </div>
  
              <div className="form-element">
                <div className="relative">
                  <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Password"
                    className="w-full bg-[#0E1015] text-white rounded-lg pl-12 pr-12 py-3 focus:outline-none focus:ring-2 focus:ring-[#611BE2] transition-all duration-300"
                    value={formData.password}
                    onChange={handleInputChange}
                    disabled={isLoading}
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-white transition-colors duration-300"
                    disabled={isLoading}
                  >
                    {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                  </button>
                </div>
              </div>
  
              <div className="form-element">
                <motion.button
                  type="submit"
                  className="w-full bg-gradient-to-r from-[#611BE2] to-[#8B5CF6] text-white rounded-lg py-3 flex items-center justify-center space-x-2 hover:opacity-90 transition-opacity duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  disabled={isLoading || !isFormValid()}
                >
                  {isLoading ? (
                    <Loader2 className="animate-spin" size={24} />
                  ) : (
                    <>
                      <span>Sign In</span>
                      <ArrowRight size={20} />
                    </>
                  )}
                </motion.button>
              </div>
            </form>
  
            <div className="mt-6 text-center text-gray-400">
              <p>Don't have an account? <a href="/register" className="text-[#8B5CF6] hover:text-white transition-colors duration-300">Sign up</a></p>
            </div>
          </motion.div>
        </div>
  
        <AnimatePresence>
          {isLoading && (
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 50 }}
              className="fixed bottom-4 right-4 bg-[#1A1C23] text-white p-4 rounded-lg shadow-lg flex items-center space-x-2"
            >
              <Loader2 className="animate-spin text-[#611BE2]" size={20} />
              <span>Signing in...</span>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  };

// Composant de succès
const RegisterSuccess: React.FC<{ email: string }> = ({ email }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    const ctx = gsap.context(() => {
      // Animation de l'icône de succès
      gsap.from('.success-icon', {
        scale: 0,
        rotation: -180,
        opacity: 0,
        duration: 0.8,
        ease: 'elastic.out(1, 0.5)',
        delay: 0.5
      });

      // Animation du texte
      gsap.from('.success-text', {
        y: 50,
        opacity: 0,
        duration: 0.6,
        stagger: 0.2,
        ease: 'power3.out',
        delay: 1
      });

      // Animation des particules
      const particles = gsap.utils.toArray('.particle');
      particles.forEach((particle: any) => {
        gsap.from(particle, {
          opacity: 0,
          scale: 0,
          x: 'random(-100, 100)',
          y: 'random(-100, 100)',
          rotation: 'random(-180, 180)',
          duration: 'random(0.8, 1.5)',
          ease: 'power3.out',
          delay: 'random(0.2, 0.8)'
        });
      });
    }, containerRef);

    return () => ctx.revert();
  }, []);

  return (
    <div ref={containerRef} className="relative min-h-screen bg-gradient-to-b from-[#0E1015] to-[#1A1C23] flex flex-col items-center justify-center p-4">
      {/* Particules d'arrière-plan */}
      {[...Array(20)].map((_, i) => (
        <div
          key={i}
          className={`particle absolute w-2 h-2 rounded-full ${
            i % 2 === 0 ? 'bg-[#611BE2]' : 'bg-[#8B5CF6]'
          }`}
          style={{
            top: `${Math.random() * 100}%`,
            left: `${Math.random() * 100}%`
          }}
        />
      ))}

      <div className="max-w-md w-full space-y-8 text-center relative z-10">
        <div className="success-icon bg-[#1A1C23] p-8 rounded-full inline-block mb-8">
          <div className="bg-[#611BE2]/20 p-6 rounded-full">
            <Check className="w-16 h-16 text-[#611BE2]" />
          </div>
        </div>

        <h2 className="success-text text-4xl font-bold text-white mb-4">
          Registration Successful!
        </h2>
        
        <p className="success-text text-gray-400 text-lg mb-8">
          Thank you for registering with Empire Drop. Our team will review your account shortly.
        </p>

        <div className="success-text bg-[#1A1C23] p-6 rounded-xl border border-[#611BE2]/20">
          <div className="flex items-center justify-center mb-4">
            <MailIcon className="w-6 h-6 text-[#611BE2] mr-2" />
            <span className="text-white">An email will be sent</span>
          </div>
          <p className="text-gray-400">
            We will send a confirmation email to <span className="text-white">{email}</span> when our
            team will confirm your account.
          </p>
        </div>

        <motion.button
          onClick={() => window.location.href = '/login'}
          className="success-text mt-8 px-8 py-3 bg-gradient-to-r from-[#611BE2] to-[#8B5CF6] text-white rounded-lg flex items-center justify-center space-x-2 hover:opacity-90 transition-opacity duration-300 mx-auto"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <span>Return to Login</span>
          <ArrowRight size={20} />
        </motion.button>
      </div>
    </div>
  );
};

// Composant principal RegisterPage
export const RegisterPage: React.FC = () => {
    const formRef = useRef<HTMLDivElement>(null);
    const logoRef = useRef<HTMLImageElement>(null);
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Error>({ 
      message: '', 
      isVisible: false,
      type: 'error',
      code: ''
    });
    const [isSuccess, setIsSuccess] = useState(false);
  
    const [formData, setFormData] = useState<RegisterFormData>({
      username: '',
      email: '',
      password: ''
    });
  
    useEffect(() => {
      const ctx = gsap.context(() => {
        gsap.from(logoRef.current, {
          duration: 1.2,
          y: -100,
          opacity: 0,
          ease: 'elastic.out(1, 0.5)',
          delay: 0.2
        });
  
        gsap.from('.auth-card', {
          duration: 1,
          y: 100,
          opacity: 0,
          ease: 'power4.out',
          delay: 0.5
        });
  
        gsap.from('.form-element', {
          duration: 0.8,
          y: 20,
          opacity: 0,
          stagger: 0.2,
          ease: 'power3.out',
          delay: 1
        });
  
        gsap.to('.bg-circle', {
          rotation: 360,
          duration: 20,
          repeat: -1,
          ease: 'none'
        });
      }, formRef);
  
      return () => ctx.revert();
    }, []);
  
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setIsLoading(true);
      setError({ 
        message: '', 
        isVisible: false, 
        type: 'error',
        code: ''
      });
  
      try {
        const response = await axios.post('https://empire-back.selaris.app/api/auth/register', {
          username: formData.username,
          email: formData.email,
          password: formData.password,
          pfp: null
        });
  
        if (response.status === 201) {
          setIsSuccess(true);
        }
      } catch (err) {
        let errorMessage = 'An error occurred during registration';
        let errorType: 'error' | 'warning' = 'error';
        let errorCode = 'REGISTRATION_ERROR';
        
        if (axios.isAxiosError(err)) {
          if (err.response?.status === 409) {
            errorMessage = 'Username or email already exists';
            errorCode = 'DUPLICATE_USER';
          } else if (err.response?.status === 400) {
            errorMessage = 'Invalid input data';
            errorCode = 'INVALID_INPUT';
          } else {
            errorMessage = err.response?.data?.message || errorMessage;
          }
        }
  
        setError({
          message: errorMessage,
          isVisible: true,
          type: errorType,
          code: errorCode
        });
  
        gsap.from('.error-message', {
          y: -20,
          opacity: 0,
          duration: 0.3,
          ease: 'power2.out'
        });
      } finally {
        setIsLoading(false);
      }
    };
  
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    };
  
    const isFormValid = (): boolean => {
      return (
        formData.username.length >= 3 &&
        formData.email.includes('@') &&
        formData.password.length >= 8
      );
    };
  
    if (isSuccess) {
      return <RegisterSuccess email={formData.email} />;
    }
  
    const getErrorStyles = () => {
      switch (error.type) {
        case 'warning':
          return 'bg-yellow-500/10 border-yellow-500/50 text-yellow-500';
        case 'info':
          return 'bg-blue-500/10 border-blue-500/50 text-blue-500';
        default:
          return 'bg-red-500/10 border-red-500/50 text-red-500';
      }
    };
  
    return (
      <div className="min-h-screen bg-gradient-to-b from-[#0E1015] to-[#1A1C23] flex flex-col items-center justify-center p-4 relative overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="bg-circle absolute w-96 h-96 rounded-full bg-gradient-to-r from-[#611BE2] to-transparent opacity-10 -top-48 -right-48"></div>
          <div className="bg-circle absolute w-96 h-96 rounded-full bg-gradient-to-r from-[#8B5CF6] to-transparent opacity-10 -bottom-48 -left-48"></div>
        </div>
  
        <div ref={formRef} className="w-full max-w-md relative z-10">
          <div className="flex flex-col items-center mb-8">
            <motion.img
              ref={logoRef}
              src="/assets/images/big-empire-drop-logo.png"
              alt="Empire Drop Logo"
              className="h-16 mb-6"
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            />
          </div>
  
          <motion.div 
            className="auth-card bg-gradient-to-br from-[#1A1C23] to-[#252A34] p-8 rounded-2xl shadow-2xl backdrop-blur-lg"
            initial={false}
          >
            <div className="text-center mb-8">
              <h2 className="text-3xl font-bold text-white mb-2">Create Account</h2>
              <p className="text-gray-400">Join us on this amazing journey</p>
            </div>
  
            {error.isVisible && (
              <motion.div 
                className={`error-message border rounded-lg p-4 mb-6 flex items-center space-x-2 ${getErrorStyles()}`}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
              >
                <AlertCircle className="text-current" size={20} />
                <p className="text-sm">{error.message}</p>
              </motion.div>
            )}

          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="form-element">
              <div className="relative">
                <UserPlus className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                <input
                  type="text"
                  name="username"
                  placeholder="Username"
                  className="w-full bg-[#0E1015] text-white rounded-lg pl-12 pr-4 py-3 focus:outline-none focus:ring-2 focus:ring-[#611BE2] transition-all duration-300"
                  value={formData.username}
                  onChange={handleInputChange}
                  disabled={isLoading}
                />
              </div>
            </div>

            <div className="form-element">
              <div className="relative">
                <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  className="w-full bg-[#0E1015] text-white rounded-lg pl-12 pr-4 py-3 focus:outline-none focus:ring-2 focus:ring-[#611BE2] transition-all duration-300"
                  value={formData.email}
                  onChange={handleInputChange}
                  disabled={isLoading}
                />
              </div>
            </div>

            <div className="form-element">
              <div className="relative">
                <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Password"
                  className="w-full bg-[#0E1015] text-white rounded-lg pl-12 pr-12 py-3 focus:outline-none focus:ring-2 focus:ring-[#611BE2] transition-all duration-300"
                  value={formData.password}
                  onChange={handleInputChange}
                  disabled={isLoading}
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-white transition-colors duration-300"
                  disabled={isLoading}
                >
                  {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                </button>
              </div>
            </div>

            <div className="form-element">
              <motion.button
                type="submit"
                className="w-full bg-gradient-to-r from-[#611BE2] to-[#8B5CF6] text-white rounded-lg py-3 flex items-center justify-center space-x-2 hover:opacity-90 transition-opacity duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                disabled={isLoading || !isFormValid()}
              >
                {isLoading ? (
                  <Loader2 className="animate-spin" size={24} />
                ) : (
                  <>
                    <span>Create Account</span>
                    <ArrowRight size={20} />
                  </>
                )}
              </motion.button>
            </div>
          </form>

          <div className="mt-6 text-center text-gray-400">
            <p>Already have an account? <a href="/login" className="text-[#8B5CF6] hover:text-white transition-colors duration-300">Sign in</a></p>
          </div>

          <motion.div 
            className="mt-6 p-4 bg-[#0E1015] rounded-lg border border-gray-800"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5 }}
          >
            <h3 className="text-white text-sm font-medium mb-2">Requirements:</h3>
            <ul className="text-gray-400 text-sm space-y-1">
            <li className="flex items-center space-x-2">
                <div className={`w-1 h-1 rounded-full ${formData.username.length >= 3 ? 'bg-green-500' : 'bg-gray-400'}`}></div>
                <span>Username must be at least 3 characters</span>
              </li>
              <li className="flex items-center space-x-2">
                <div className={`w-1 h-1 rounded-full ${formData.email.includes('@') ? 'bg-green-500' : 'bg-gray-400'}`}></div>
                <span>Valid email address</span>
              </li>
              <li className="flex items-center space-x-2">
                <div className={`w-1 h-1 rounded-full ${formData.password.length >= 8 ? 'bg-green-500' : 'bg-gray-400'}`}></div>
                <span>Password must be at least 8 characters</span>
              </li>
            </ul>
          </motion.div>
        </motion.div>
      </div>

      <AnimatePresence>
        {isLoading && (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            className="fixed bottom-4 right-4 bg-[#1A1C23] text-white p-4 rounded-lg shadow-lg flex items-center space-x-2"
          >
            <Loader2 className="animate-spin text-[#611BE2]" size={20} />
            <span>Creating your account...</span>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

// Export avec un type spécifique pour l'export par défaut
interface RegisterComponents {
  RegisterPage: React.FC;
}

const exports: RegisterComponents = {
  RegisterPage
};



export default {
  LoginPage,
  RegisterPage
};
import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Settings, MessageSquare, Hash, CheckCircle2, AlertCircle, Search, Users, Trash2, ChevronLeft, ChevronRight, Trophy } from 'lucide-react';
import axios from 'axios';


type Toast = {
  id: number;
  message: string;
  type: 'success' | 'error';
};

type Participant = {
  id: number;
  username: string;
  avatar: string;
};

type LastWinnerProps = {
  winner?: {
    username: string;
    avatar: string;
    timestamp: string;
  };
};

interface Winner {
  id: number;
  username: string;
  avatar: string;
}

interface WinnerResponse {
  success: boolean;
  data: Winner | null;
  message: string;
}

const LastWinner: React.FC = () => {
  const [winner, setWinner] = useState<Winner | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchWinner = async () => {
      try {
        const response = await axios.get<WinnerResponse>('https://empire-back.selaris.app/api/winner');
        setWinner(response.data.data);
      } catch (err) {
        console.error('Error fetching winner:', err);
        setError('Failed to load winner');
      } finally {
        setIsLoading(false);
      }
    };

    fetchWinner();
    // Rafraîchir toutes les 5 secondes comme pour les participants
    const interval = setInterval(fetchWinner, 5000);
    return () => clearInterval(interval);
  }, []);

  if (isLoading) {
    return (
      <motion.div
        className="p-6 bg-gradient-to-br from-[#1A1C23] to-[#252A34] rounded-2xl shadow-2xl mb-6"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="flex items-center justify-center text-gray-400 py-2">
          <span>Loading...</span>
        </div>
      </motion.div>
    );
  }

  if (error) {
    return (
      <motion.div
        className="p-6 bg-gradient-to-br from-[#1A1C23] to-[#252A34] rounded-2xl shadow-2xl mb-6"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="flex items-center justify-center text-red-400 py-2">
          <span>{error}</span>
        </div>
      </motion.div>
    );
  }

  if (!winner) {
    return (
      <motion.div
        className="p-6 bg-gradient-to-br from-[#1A1C23] to-[#252A34] rounded-2xl shadow-2xl mb-6"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="flex items-center justify-center text-gray-400 py-2">
          <Trophy className="mr-2 text-[#611BE2]" size={20} />
          <span>No previous winner</span>
        </div>
      </motion.div>
    );
  }

  return (
    <motion.div
      className="p-6 bg-gradient-to-br from-[#1A1C23] to-[#252A34] rounded-2xl shadow-2xl mb-6"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <h2 className="text-lg font-medium text-gray-400 mb-4 flex items-center">
        <Trophy className="mr-2 text-[#611BE2]" size={20} />
        Last Winner
      </h2>
      <div className="flex items-center space-x-4">
        <motion.div 
          className="w-12 h-12 rounded-full border-2 border-[#611BE2] overflow-hidden"
          whileHover={{ scale: 1.05 }}
        >
          <img 
            src={winner.avatar} 
            alt={winner.username} 
            className="w-full h-full object-cover"
          />
        </motion.div>
        <div className="flex flex-col">
          <span className="text-white font-medium">{winner.username}</span>
        </div>
      </div>
    </motion.div>
  );
};

const ParticipantsCard: React.FC<{
  participants: Participant[];
  onDelete: (id: number) => Promise<void>;
  isDeleteLoading: number | null;
  addToast: (message: string, type: 'success' | 'error') => void;
}> = ({ participants, onDelete, isDeleteLoading, addToast }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isClearingAll, setIsClearingAll] = useState(false);
  const ITEMS_PER_PAGE = 6;

  const filteredParticipants = participants.filter(participant =>
    participant.username.toLowerCase().includes(searchTerm.toLowerCase())
  );


  const totalPages = Math.ceil(filteredParticipants.length / ITEMS_PER_PAGE);
  const currentParticipants = filteredParticipants.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  const handleClearAll = async () => {
    setIsClearingAll(true);
    try {
      await Promise.all(participants.map(p => onDelete(p.id)));
      addToast('All participants have been removed', 'success');
      setIsConfirmOpen(false);
    } catch (error) {
      addToast('Failed to remove all participants', 'error');
    } finally {
      setIsClearingAll(false);
    }
  };

  return (
    <motion.div
      className="mt-8 p-8 bg-gradient-to-br from-[#1A1C23] to-[#252A34] rounded-2xl shadow-2xl"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6 space-y-4 md:space-y-0">
        <div className="flex items-center space-x-4">
          <h2 className="text-2xl font-bold text-white flex items-center">
            <Users className="mr-2 text-[#611BE2]" size={24} />
            Participants ({filteredParticipants.length})
          </h2>
          {participants.length > 0 && (
            <motion.button
              onClick={() => setIsConfirmOpen(true)}
              className="px-4 py-2 bg-red-500/10 text-red-500 rounded-lg font-medium hover:bg-red-500/20 transition-colors flex items-center space-x-2"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              disabled={isClearingAll}
            >
              <Trash2 size={16} />
              <span>Clear All</span>
            </motion.button>
          )}
        </div>
        <div className="relative w-full md:w-auto">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#611BE2]" size={20} />
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full md:w-64 bg-[#2C3038] text-white border-2 border-[#3D4149] rounded-lg py-2 px-4 pl-12 focus:outline-none focus:border-[#611BE2] transition-colors duration-300"
            placeholder="Search participants..."
          />
        </div>
      </div>

      {filteredParticipants.length === 0 ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="text-center py-8 text-gray-400"
        >
          {searchTerm ? 'No participants found matching your search' : 'No participants yet'}
        </motion.div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
          <AnimatePresence mode="popLayout">
            {currentParticipants.map((participant) => (
              <motion.div
                key={participant.id}
                className="bg-[#2C3038] p-4 rounded-xl border-2 border-[#3D4149] flex items-center justify-between transform hover:scale-105 transition-transform duration-200"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
                layout
              >
                <div className="flex items-center space-x-3">
                  <div className="w-10 h-10 rounded-full overflow-hidden border-2 border-[#611BE2]">
                    <img
                      src={participant.avatar}
                      alt={participant.username}
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <span className="text-white font-medium truncate">{participant.username}</span>
                </div>
                <motion.button
                  onClick={() => onDelete(participant.id)}
                  className="text-red-500 hover:text-red-400 transition-colors disabled:opacity-50 p-2 hover:bg-red-500/10 rounded-lg"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  disabled={isDeleteLoading === participant.id || isClearingAll}
                >
                  <Trash2 size={20} />
                </motion.button>
              </motion.div>
            ))}
          </AnimatePresence>
        </div>
      )}

      {totalPages > 1 && (
        <div className="flex justify-center items-center space-x-4 mt-6">
          <motion.button
            onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
            className="text-[#611BE2] disabled:text-gray-600 p-2 hover:bg-[#611BE2]/10 rounded-lg"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            disabled={currentPage === 1}
          >
            <ChevronLeft size={24} />
          </motion.button>
          <span className="text-white font-medium">
            Page {currentPage} of {totalPages}
          </span>
          <motion.button
            onClick={() => setCurrentPage(p => Math.min(totalPages, p + 1))}
            className="text-[#611BE2] disabled:text-gray-600 p-2 hover:bg-[#611BE2]/10 rounded-lg"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            disabled={currentPage === totalPages}
          >
            <ChevronRight size={24} />
          </motion.button>
        </div>
      )}

      {/* Confirmation Modal */}
      <AnimatePresence>
        {isConfirmOpen && (
          <motion.div
            className="fixed inset-0 bg-black/50 flex items-center justify-center z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => !isClearingAll && setIsConfirmOpen(false)}
          >
            <motion.div
              className="bg-[#1A1C23] p-6 rounded-xl border-2 border-[#3D4149] max-w-md w-full mx-4"
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              onClick={e => e.stopPropagation()}
            >
              <h3 className="text-xl font-bold text-white mb-4">Clear All Participants?</h3>
              <p className="text-gray-400 mb-6">
                Are you sure you want to remove all participants? This action cannot be undone.
              </p>
              <div className="flex space-x-4">
                <motion.button
                  onClick={handleClearAll}
                  className="flex-1 bg-red-500 text-white py-2 rounded-lg font-medium hover:bg-red-600 transition-colors disabled:opacity-50"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  disabled={isClearingAll}
                >
                  {isClearingAll ? 'Clearing...' : 'Clear All'}
                </motion.button>
                <motion.button
                  onClick={() => !isClearingAll && setIsConfirmOpen(false)}
                  className="flex-1 bg-[#3D4149] text-white py-2 rounded-lg font-medium hover:bg-[#4D5159] transition-colors disabled:opacity-50"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  disabled={isClearingAll}
                >
                  Cancel
                </motion.button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};


const DrawSettings: React.FC = () => {
  const [chatKeyword, setChatKeyword] = useState('');
  const [currentKeyword, setCurrentKeyword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isDrawInitiating, setIsDrawInitiating] = useState(false);
  const [toasts, setToasts] = useState<Toast[]>([]);
  const [participants, setParticipants] = useState<Participant[]>([]);
  const [isDeleteLoading, setIsDeleteLoading] = useState<number | null>(null);

  const addToast = (message: string, type: 'success' | 'error') => {
    const newToast = {
      id: Date.now(),
      message,
      type,
    };
    setToasts((currentToasts) => [...currentToasts, newToast]);
    setTimeout(() => {
      setToasts((currentToasts) => 
        currentToasts.filter((toast) => toast.id !== newToast.id)
      );
    }, 3000);
  };

  const clearAllParticipants = async () => {
    try {
      await Promise.all(participants.map(p => 
        axios.delete(`https://empire-back.selaris.app/api/participants/${p.id}`)
      ));
      setParticipants([]);
      addToast('All participants have been cleared', 'success');
    } catch (err) {
      addToast('Failed to clear all participants', 'error');
      console.error('Error clearing participants:', err);
    }
  };

  const fetchParticipants = async () => {
    try {
      const response = await axios.get('https://empire-back.selaris.app/api/participants');
      setParticipants(response.data.data);
    } catch (err) {
      addToast('Failed to load participants', 'error');
      console.error('Error fetching participants:', err);
    }
  };

  useEffect(() => {
    fetchParticipants();
    const interval = setInterval(fetchParticipants, 5000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const fetchCurrentKeyword = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get('https://empire-back.selaris.app/api/overlay/keyword');
        setCurrentKeyword(response.data.data.keyword);
        setChatKeyword(response.data.data.keyword);
      } catch (err) {
        addToast('Failed to load current keyword', 'error');
        console.error('Error fetching keyword:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCurrentKeyword();
  }, []);

  useEffect(() => {
    const updateKeyword = async () => {
      if (chatKeyword === currentKeyword) return;
      
      try {
        setIsLoading(true);
        await axios.put('https://empire-back.selaris.app/api/overlay/keyword', {
          keyword: chatKeyword
        });
        setCurrentKeyword(chatKeyword);
        addToast('Keyword updated successfully!', 'success');
      } catch (err) {
        addToast('Failed to update keyword', 'error');
        console.error('Error updating keyword:', err);
      } finally {
        setIsLoading(false);
      }
    };

    const timeoutId = setTimeout(() => {
      if (chatKeyword && chatKeyword !== currentKeyword) {
        updateKeyword();
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [chatKeyword]);

  const handleInitiateDraw = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsDrawInitiating(true);

    try {
      await axios.post('https://empire-back.selaris.app/api/game/restart');
      addToast('Draw successfully initiated! 🎲', 'success');
      await clearAllParticipants();
    } catch (err) {
      addToast('Failed to initiate draw', 'error');
      console.error('Error initiating draw:', err);
    } finally {
      setIsDrawInitiating(false);
    }
  };

  const handleDeleteParticipant = async (id: number) => {
    setIsDeleteLoading(id);
    try {
      await axios.delete(`https://empire-back.selaris.app/api/participants/${id}`);
      setParticipants(participants.filter(p => p.id !== id));
      addToast('Participant removed successfully', 'success');
    } catch (err) {
      addToast('Failed to remove participant', 'error');
      console.error('Error removing participant:', err);
    } finally {
      setIsDeleteLoading(null);
    }
  };

  return (
    <>
     
      <motion.div
        className="p-8 bg-gradient-to-br from-[#1A1C23] to-[#252A34] rounded-2xl shadow-2xl overflow-hidden relative"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h1 className="text-4xl font-bold text-white mb-8 flex items-center">
          <Settings className="mr-4 text-[#611BE2]" size={36} />
          Draw Configuration
        </h1>

        <form onSubmit={handleInitiateDraw} className="space-y-8">
          <motion.div
            className="space-y-2"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
          >
            <label className="block text-sm font-medium text-gray-400">Chat Keyword</label>
            <div className="relative">
              <MessageSquare className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#611BE2]" size={20} />
              <input
                type="text"
                value={chatKeyword}
                onChange={(e) => setChatKeyword(e.target.value)}
                className="w-full bg-[#2C3038] text-white border-2 border-[#3D4149] rounded-lg py-3 px-4 pl-12 focus:outline-none focus:border-[#611BE2] transition-colors duration-300"
                placeholder="Enter keyword for chat participation"
              />
              {isLoading && (
                <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-[#611BE2] text-sm">
                  Saving...
                </span>
              )}
            </div>
          </motion.div>

          <motion.div
            className="bg-[#2C3038] p-6 rounded-xl border-2 border-[#3D4149]"
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.5 }}
          >
            <h3 className="text-xl font-semibold text-white mb-4 flex items-center">
              <Hash className="mr-2 text-[#611BE2]" size={24} />
              Keyword Preview
            </h3>
            <p className="text-gray-400">
              Participants will need to type <span className="text-[#611BE2] font-semibold">!{currentKeyword || 'keyword'}</span> in the chat to enter the draw.
            </p>
          </motion.div>

          <motion.button
            type="submit"
            className="w-full bg-gradient-to-r from-[#611BE2] to-[#8B5CF6] text-white py-4 rounded-xl font-bold text-lg transition duration-300 hover:from-[#8B5CF6] hover:to-[#611BE2] shadow-lg disabled:opacity-50 disabled:cursor-not-allowed"
            whileHover={{ scale: isDrawInitiating ? 1 : 1.02, boxShadow: "0 0 15px rgba(139, 92, 246, 0.5)" }}
            whileTap={{ scale: isDrawInitiating ? 1 : 0.98 }}
            disabled={isDrawInitiating}
          >
            {isDrawInitiating ? 'Initiating...' : 'Initiate Draw'}
          </motion.button>
        </form>
      </motion.div>
      <br />
      <LastWinner />
      <ParticipantsCard
        participants={participants}
        onDelete={handleDeleteParticipant}
        isDeleteLoading={isDeleteLoading}
        addToast={addToast}
      />

 {/* Toast Container - Suite et fin */}
 <div className="fixed bottom-4 right-4 space-y-2 z-50">
        <AnimatePresence>
          {toasts.map((toast) => (
            <motion.div
              key={toast.id}
              initial={{ opacity: 0, y: 20, scale: 0.95 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              exit={{ opacity: 0, y: -20, scale: 0.95 }}
              className={`p-4 rounded-lg shadow-lg flex items-center space-x-2 ${
                toast.type === 'success' 
                  ? 'bg-green-500 text-white' 
                  : 'bg-red-500 text-white'
              }`}
            >
              {toast.type === 'success' ? (
                <CheckCircle2 className="w-5 h-5" />
              ) : (
                <AlertCircle className="w-5 h-5" />
              )}
              <span className="font-medium">{toast.message}</span>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    </>
  );
};

export default DrawSettings;
import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { motion } from 'framer-motion';
import { Ticket, Users, TrendingUp, Activity, ShipWheel } from 'lucide-react';

gsap.registerPlugin(ScrollTrigger);

const DashboardCard: React.FC<{ 
  title: string; 
  value: string | number; 
  icon: React.ReactNode; 
  color: string;
  isLoading?: boolean;
}> = ({ title, value, icon, color, isLoading }) => (
  <motion.div 
    className={`bg-gradient-to-br from-[#1A1C23] to-[#252A34] p-6 rounded-xl shadow-lg`}
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    <div className="flex items-center justify-between mb-4">
      <h3 className="text-lg font-semibold text-gray-300">{title}</h3>
      <div className={`p-3 rounded-full ${color}`}>
        {icon}
      </div>
    </div>
    {isLoading ? (
      <div className="h-9 bg-gray-700 rounded animate-pulse" />
    ) : (
      <p className="text-3xl font-bold text-white">{value.toLocaleString()}</p>
    )}
  </motion.div>
);

const MainContent: React.FC = () => {
  const chartRef = useRef<HTMLDivElement>(null);
  const [promoCodeCount, setPromoCodeCount] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchPromoCodeCount = async () => {
      try {
        const response = await fetch('https://empire-back.selaris.app/api/promo-codes/count');
        const data = await response.json();
        if (data.status === 'success') {
          setPromoCodeCount(data.data.total);
        } else {
          console.error('Failed to fetch promo code count:', data);
        }
      } catch (error) {
        console.error('Error fetching promo code count:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPromoCodeCount();
  }, []);

  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.from('.dashboard-card', {
        duration: 0.8,
        opacity: 0,
        y: 50,
        stagger: 0.2,
        ease: 'power3.out',
      });

      gsap.from('.chart-bar', {
        scaleY: 0,
        transformOrigin: 'bottom',
        stagger: 0.1,
        duration: 1,
        ease: 'elastic.out(1, 0.5)',
        scrollTrigger: {
          trigger: chartRef.current,
          start: 'top 80%',
        },
      });
    });

    return () => ctx.revert();
  }, []);

  return (
    <div className="p-6 space-y-6">
      <motion.h1 
        className="text-3xl font-bold text-white mb-8"
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5 }}
      >
        Dashboard Overview
      </motion.h1>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <DashboardCard 
          title="Total Promo Codes" 
          value={promoCodeCount ?? 0} 
          icon={<Ticket size={24} className="text-white" />} 
          color="bg-purple-500"
          isLoading={isLoading}
        />
        <DashboardCard 
          title="Live View Counter" 
          value="0" 
          icon={<Users size={24} className="text-white" />} 
          color="bg-blue-500" 
        />
        <DashboardCard 
          title="Total Draw" 
          value="0" 
          icon={<ShipWheel size={24} className="text-white" />} 
          color="bg-green-500" 
        />
        <DashboardCard 
          title="UP Stats" 
          value="89 %" 
          icon={<Activity size={24} className="text-white" />} 
          color="bg-red-500" 
        />
      </div>

      <div className="bg-gradient-to-br from-[#1A1C23] to-[#252A34] p-6 rounded-xl shadow-lg mt-8">
        <h2 className="text-2xl font-bold text-white mb-6">Weekly Activity</h2>
        <div ref={chartRef} className="h-64 flex items-end justify-between">
          {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((day, index) => (
            <div key={day} className="flex flex-col items-center">
              <div 
                className="chart-bar w-12 bg-gradient-to-t from-[#611BE2] to-[#8B5CF6] rounded-t-md"
                style={{ height: `${Math.random() * 100}%` }}
              ></div>
              <span className="text-gray-400 mt-2">{day}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MainContent;
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import gsap from 'gsap';
import { 
  Box, Gift, Settings, ChevronLeft, ChevronRight, Search, 
  ToggleLeft, ToggleRight, Filter, Loader2, AlertCircle, RefreshCw,
  DollarSign, Package, LayersIcon, CheckCircle,
  Eye,
  EyeOff,
  Sliders,
  X,
  RotateCcw
} from 'lucide-react';

// Types definitions
interface Tag {
  name: string;
  slug: string;
  color: string;
}


interface FilterState {
  priceMin: number;
  priceMax: number;
  categories: string[];
}

interface Category {
  name: string;
  slug: string;
  type: string;
}


interface SlugPrice {
  price: number;
  slug: string;
}

interface BoxData {
  name: string;
  slug: string;
  image: string;
  price: number;
  tags: Tag[];
  enabled: boolean;
  categories: Category[];
}

const FilterMenu: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  filters: FilterState;
  onFiltersChange: (filters: FilterState) => void;
  availableCategories: { name: string; slug: string; type: string; }[];
}> = ({ isOpen, onClose, filters, onFiltersChange, availableCategories }) => {
  const [localFilters, setLocalFilters] = useState(filters);

  const handlePriceChange = (type: 'min' | 'max', value: string) => {
    const numValue = value === '' ? (type === 'min' ? 0 : 500000) : parseInt(value);
    setLocalFilters(prev => ({
      ...prev,
      [type === 'min' ? 'priceMin' : 'priceMax']: numValue
    }));
  };

  const handleCategoryToggle = (slug: string) => {
    setLocalFilters(prev => ({
      ...prev,
      categories: prev.categories.includes(slug)
        ? prev.categories.filter(cat => cat !== slug)
        : [...prev.categories, slug]
    }));
  };

  const handleApply = () => {
    onFiltersChange(localFilters);
    onClose();
  };

  const handleReset = () => {
    const resetFilters = {
      priceMin: 0,
      priceMax: 500000,
      categories: []
    };
    setLocalFilters(resetFilters);
    onFiltersChange(resetFilters);
  };

  if (!isOpen) return null;

  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="absolute right-0 top-full mt-2 w-80 bg-[#1A1C23] border border-[#3D4149] rounded-xl shadow-xl z-50"
    >
      <div className="p-4">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-white font-semibold flex items-center gap-2">
            <Sliders size={18} className="text-[#611BE2]" />
            Filters
          </h3>
          <button
            onClick={onClose}
            className="p-1 hover:bg-[#2C3038] rounded-lg transition-colors"
          >
            <X size={18} className="text-gray-400" />
          </button>
        </div>

        <div className="space-y-4">
          <div>
            <h4 className="text-gray-400 text-sm mb-2">Price Range (€)</h4>
            <div className="flex gap-2">
              <input
                type="number"
                value={localFilters.priceMin / 100}
                onChange={(e) => handlePriceChange('min', (parseFloat(e.target.value) * 100).toString())}
                min="0"
                max={localFilters.priceMax / 100}
                className="w-1/2 bg-[#2C3038] text-white px-3 py-2 rounded-lg border border-[#3D4149] focus:border-[#611BE2] focus:outline-none"
                placeholder="Min"
              />
              <input
                type="number"
                value={localFilters.priceMax / 100}
                onChange={(e) => handlePriceChange('max', (parseFloat(e.target.value) * 100).toString())}
                min={localFilters.priceMin / 100}
                className="w-1/2 bg-[#2C3038] text-white px-3 py-2 rounded-lg border border-[#3D4149] focus:border-[#611BE2] focus:outline-none"
                placeholder="Max"
              />
            </div>
          </div>

          <div>
            <h4 className="text-gray-400 text-sm mb-2">Categories</h4>
            <div className="space-y-2 max-h-40 overflow-y-auto custom-scrollbar">
              {availableCategories.map((category) => (
                <label
                  key={category.slug}
                  className="flex items-center gap-2 text-white cursor-pointer p-2 hover:bg-[#2C3038] rounded-lg"
                >
                  <input
                    type="checkbox"
                    checked={localFilters.categories.includes(category.slug)}
                    onChange={() => handleCategoryToggle(category.slug)}
                    className="rounded border-[#3D4149] text-[#611BE2] focus:ring-[#611BE2]"
                  />
                  {category.name}
                </label>
              ))}
            </div>
          </div>
        </div>

        <div className="flex gap-2 mt-4">
          <motion.button
            onClick={handleReset}
            className="flex-1 px-4 py-2 bg-[#2C3038] text-white rounded-lg hover:bg-[#3D4149] transition-colors"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            Reset
          </motion.button>
          <motion.button
            onClick={handleApply}
            className="flex-1 px-4 py-2 bg-[#611BE2] text-white rounded-lg hover:bg-[#4F15B8] transition-colors"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            Apply
          </motion.button>
        </div>
      </div>
    </motion.div>
  );
};

// Composants utilitaires
const LoadingSpinner: React.FC = () => (
  <div className="flex items-center justify-center p-8">
    <Loader2 className="w-8 h-8 text-[#611BE2] animate-spin" />
  </div>
);

const ErrorMessage: React.FC<{ message: string; onRetry: () => void }> = ({ message, onRetry }) => (
  <div className="flex flex-col items-center justify-center p-8 bg-red-500/10 rounded-lg">
    <div className="flex items-center mb-4">
      <AlertCircle className="w-6 h-6 text-red-500 mr-2" />
      <span className="text-red-500">{message}</span>
    </div>
    <motion.button
      onClick={onRetry}
      className="flex items-center px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      <RefreshCw className="w-4 h-4 mr-2" />
      Retry
    </motion.button>
  </div>
);

const StatCard: React.FC<{ 
  title: string; 
  value: string | number; 
  icon: React.ReactNode;
  index: number;
}> = ({ title, value, icon, index }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ delay: index * 0.1 }}
    className="bg-[#2C3038] p-4 rounded-lg border border-[#3D4149] hover:border-[#611BE2] transition-all duration-300 group"
  >
    <div className="flex items-center justify-between mb-2">
      <p className="text-gray-400 text-sm">{title}</p>
      <div className="text-[#611BE2] group-hover:scale-110 transition-transform duration-300">
        {icon}
      </div>
    </div>
    <p className="text-2xl font-bold text-white">{value}</p>
  </motion.div>
);

const LoadingAnimation: React.FC = () => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    className="fixed inset-0 bg-black/80 backdrop-blur-sm z-50 flex flex-col items-center justify-center"
  >
    <motion.div
      initial={{ scale: 0.8, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      exit={{ scale: 0.8, opacity: 0 }}
      className="text-center"
    >
      <motion.div
        className="w-24 h-24 mb-6 relative mx-auto"
        animate={{ rotate: 360 }}
        transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
      >
        <div className="absolute inset-0 rounded-full border-4 border-t-[#611BE2] border-r-[#611BE2/30] border-b-[#611BE2/10] border-l-[#611BE2/60]" />
      </motion.div>
      <motion.h2 
        className="text-3xl font-bold text-white mb-4"
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.2 }}
      >
        Animation Started
      </motion.h2>
      <motion.div
        className="flex justify-center gap-1"
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.4 }}
      >
        {[0, 1, 2].map((i) => (
          <motion.div
            key={i}
            className="w-2 h-2 rounded-full bg-[#611BE2]"
            animate={{
              scale: [1, 1.5, 1],
              opacity: [1, 0.5, 1],
            }}
            transition={{
              duration: 1,
              repeat: Infinity,
              delay: i * 0.2,
            }}
          />
        ))}
      </motion.div>
    </motion.div>
  </motion.div>
);

const LatestBoxes: React.FC = () => {
  const [latestBoxes, setLatestBoxes] = useState<BoxData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const empireDropDataRef = useRef<any[]>([]);

  const findBoxByName = (boxes: any[], name: string) => {
    // Recherche exacte
    const exactMatch = boxes.find(box => box.name === name);
    if (exactMatch) return exactMatch;

    // Recherche insensible à la casse
    const lowerName = name.toLowerCase();
    return boxes.find(box => box.name.toLowerCase() === lowerName);
  };

  const fetchLatestBoxes = async () => {
    try {
      // Récupérer les données EmpireDrop si pas encore fait
      if (empireDropDataRef.current.length === 0) {
        const empireResponse = await fetch('https://api.empiredrop.com/api/v1/boxes/search', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            brands: [],
            categories: [],
            order: 'CHEAPEST',
            price_max: 500000,
            price_min: 0,
            tags: []
          })
        });

        if (!empireResponse.ok) {
          throw new Error('Failed to fetch EmpireDrop data');
        }

        empireDropDataRef.current = await empireResponse.json();
      }

      // Récupérer les boxes locales
      const localResponse = await fetch('https://empire-back.selaris.app/api/boxes');
      if (!localResponse.ok) throw new Error('Failed to fetch local boxes');
      
      const localData = await localResponse.json();
      const boxNames = [
        localData.data[0]?.box1,
        localData.data[0]?.box2,
        localData.data[0]?.box3,
        localData.data[0]?.box4,
        localData.data[0]?.box5,
      ].filter(Boolean);

      // Mapper les boxes dans l'ordre des noms reçus
      const matchingBoxes = boxNames.map(name => {
        const box = findBoxByName(empireDropDataRef.current, name);
        if (!box) {
          console.warn(`Box not found: ${name}`);
          return null;
        }

        return {
          name: box.name,
          slug: box.slug,
          image: box.image.startsWith('/')
            ? `https://cdn.empiredrop.com${box.image}`
            : `https://cdn.empiredrop.com/${box.image}`,
          price: box.price,
          tags: box.tags || [],
          enabled: true,
          categories: box.categories || []
        };
      }).filter(Boolean) as BoxData[]; // Filtrer les nulls et typer correctement

      setLatestBoxes(matchingBoxes);
      setIsLoading(false);

      // Si on a moins de boxes que prévu, recharger les données EmpireDrop
      if (matchingBoxes.length < boxNames.length) {
        empireDropDataRef.current = []; // Force le rechargement au prochain intervalle
      }
    } catch (err) {
      console.error('Error fetching boxes:', err);
      setError(err instanceof Error ? err.message : 'Error fetching boxes');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLatestBoxes();
    const interval = setInterval(fetchLatestBoxes, 10000);
    return () => clearInterval(interval);
  }, []);

  if (isLoading) {
    return (
      <div className="text-center py-8 text-gray-400">
        <Loader2 className="w-8 h-8 text-[#611BE2] animate-spin mx-auto" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-8 text-red-400">
        <AlertCircle className="w-8 h-8 mx-auto mb-2" />
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-5 gap-4">
      {latestBoxes.map((box, index) => (
        <motion.div
          key={box.slug || index} // Fallback sur l'index si pas de slug
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: index * 0.1 }}
          className="bg-[#1A1C23] rounded-lg overflow-hidden group"
        >
          <div className="relative">
            <img 
              src={box.image} 
              alt={box.name}
              className="w-full h-32 object-cover transition-transform duration-300 group-hover:scale-105"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
          </div>
          <div className="p-4">
            <h3 className="text-white font-semibold truncate mb-1">{box.name}</h3>
            <p className="text-[#611BE2] font-medium">{(box.price / 100).toFixed(2)} €</p>
            <div className="flex flex-wrap gap-1 mt-2">
              {box.tags.slice(0, 2).map((tag) => (
                <span
                  key={tag.slug}
                  className="px-2 py-0.5 rounded-full text-xs font-medium"
                  style={{ backgroundColor: `#${tag.color}40` }}
                >
                  {tag.name}
                </span>
              ))}
            </div>
          </div>
        </motion.div>
      ))}
    </div>
  );
};

const RandomBoxSelector: React.FC<{ selectedBox: BoxData | null; onStart: () => void }> = ({ 
  selectedBox, 
  onStart 
 }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showLoadingAnimation, setShowLoadingAnimation] = useState(false);
 
  const handleStart = async () => {
    try {
      setIsLoading(true);
      setError(null);
      setShowLoadingAnimation(true);
      
      const response = await fetch('https://empire-back.selaris.app/api/reset-and-activate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });
 
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
 
      await new Promise(resolve => setTimeout(resolve, 10000));
 
      if (selectedBox) {
        const createBoxesResponse = await fetch('https://empire-back.selaris.app/api/boxes', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            boxes: Array(5).fill({
              box1: selectedBox.name,
              box2: selectedBox.name,
              box3: selectedBox.name,
              box4: selectedBox.name,
              box5: selectedBox.name
            })
          })
        });
 
        if (!createBoxesResponse.ok) {
          throw new Error(`HTTP error! status: ${createBoxesResponse.status}`);
        }
 
        onStart();
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
      console.error('Error during process:', err);
    } finally {
      setIsLoading(false);
      setShowLoadingAnimation(false);
    }
  };
 
  const handleResetV2 = async () => {
    try {
      setIsLoading(true);
      setError(null);
      
      const response = await fetch('https://empire-back.selaris.app/api/reset-all', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });
 
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
 
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
      console.error('Error during reset:', err);
    } finally {
      setIsLoading(false);
    }
  };
 
  return (
    <>
      <AnimatePresence>
        {showLoadingAnimation && <LoadingAnimation />}
      </AnimatePresence>
 
      <motion.div
        className="bg-gradient-to-br from-[#1A1C23] to-[#252A34] p-6 rounded-xl shadow-2xl mb-8 border border-[#3D4149] hover:border-[#611BE2] transition-all duration-300"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
      >
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center gap-4">
            <Gift size={24} className="text-[#611BE2]" />
            <h2 className="text-2xl font-bold text-white">Box Selector</h2>
          </div>
          <motion.button
            onClick={handleResetV2}
            disabled={isLoading}
            className="flex items-center gap-2 px-4 py-2 bg-[#EF4444] text-white rounded-lg hover:bg-[#DC2626] transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            whileHover={{ scale: isLoading ? 1 : 1.05 }}
            whileTap={{ scale: isLoading ? 1 : 0.95 }}
          >
            <RotateCcw size={18} />
            Reset
          </motion.button>
        </div>
 
        <LatestBoxes />
 
        {error && (
          <div className="mb-4 p-4 bg-red-500/10 border border-red-500/20 rounded-lg text-red-400 text-sm">
            {error}
          </div>
        )}
        <br />
        <motion.button
          onClick={handleStart}
          disabled={isLoading}
          className={`w-full bg-gradient-to-r from-[#611BE2] to-[#8B5CF6] text-white py-4 rounded-xl font-bold text-lg relative overflow-hidden group 
            ${isLoading ? 'opacity-75 cursor-not-allowed' : ''}`}
          whileHover={{ scale: isLoading ? 1 : 1.02 }}
          whileTap={{ scale: isLoading ? 1 : 0.98 }}
        >
          <span className="relative z-10 flex items-center justify-center gap-2">
            {isLoading ? (
              <>
                <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                </svg>
                Processing...
              </>
            ) : (
              'Start Random Selection'
            )}
          </span>
          <div className="absolute inset-0 bg-gradient-to-r from-[#8B5CF6] to-[#611BE2] opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
        </motion.button>
      </motion.div>
    </>
  );
 };

const BoxCard: React.FC<{
  box: BoxData;
  onToggle: (slug: string) => void;
}> = ({ box, onToggle }) => {
  const cardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (cardRef.current) {
      gsap.fromTo(cardRef.current,
        { 
          opacity: 0,
          y: 20
        },
        { 
          opacity: 1,
          y: 0,
          duration: 0.5,
          ease: "power3.out"
        }
      );
    }
  }, []);

  return (
    <div
      ref={cardRef}
      className="box-card bg-gradient-to-br from-[#1A1C23] to-[#252A34] p-4 rounded-xl border border-[#3D4149] hover:border-[#611BE2] transition-all duration-300 group"
    >
      <div className="flex gap-4">
        <div className="relative group/image">
          <img 
            src={box.image} 
            alt={box.name}
            className="w-24 h-24 rounded-lg object-cover transition-all duration-300 group-hover/image:scale-105 group-hover/image:shadow-lg"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent rounded-lg opacity-0 group-hover/image:opacity-100 transition-opacity duration-300" />
        </div>
        <div className="flex-1">
          <h3 className="text-lg font-semibold text-white mb-1">{box.name}</h3>
          <p className="text-[#611BE2] font-medium">{(box.price / 100).toFixed(2)} €</p>
          <div className="flex flex-wrap gap-2 mt-2">
            {box.tags.map((tag) => (
              <span
                key={tag.slug}
                className="px-2 py-1 rounded-full text-xs font-medium relative overflow-hidden group/tag"
                style={{ backgroundColor: `#${tag.color}40` }}
              >
                <span className="relative z-10">{tag.name}</span>
                <div 
                  className="absolute inset-0 opacity-20 group-hover/tag:opacity-30 transition-opacity duration-300"
                  style={{ backgroundColor: `#${tag.color}` }}
                />
              </span>
            ))}
          </div>
        </div>
        <motion.button
          onClick={() => onToggle(box.slug)}
          className="self-start p-2 rounded-lg hover:bg-[#611BE2]/10 transition-all duration-300"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
        >
          {box.enabled ? (
            <ToggleRight className="w-6 h-6 text-[#611BE2]" />
          ) : (
            <ToggleLeft className="w-6 h-6 text-gray-400" />
          )}
        </motion.button>
      </div>
    </div>
  );
};

const BoxGrid: React.FC<{ 
    boxes: BoxData[]; 
    onToggle: (slug: string) => void;
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
  }> = ({ boxes, onToggle, currentPage, totalPages, onPageChange }) => {
    return (
      <div className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {boxes.map((box, index) => (
            <BoxCard 
              key={box.slug} 
              box={box} 
              onToggle={onToggle}
            />
          ))}
        </div>
  
        {totalPages > 1 && (
          <div className="flex justify-center items-center gap-4 mt-8">
            <motion.button
              onClick={() => onPageChange(Math.max(1, currentPage - 1))}
              disabled={currentPage === 1}
              className="p-2 rounded-lg text-[#611BE2] disabled:text-gray-600 hover:bg-[#611BE2]/10 transition-all duration-300"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
            >
              <ChevronLeft size={24} />
            </motion.button>
            <span className="text-white font-medium">
              Page {currentPage} of {totalPages}
            </span>
            <motion.button
              onClick={() => onPageChange(Math.min(totalPages, currentPage + 1))}
              disabled={currentPage === totalPages}
              className="p-2 rounded-lg text-[#611BE2] disabled:text-gray-600 hover:bg-[#611BE2]/10 transition-all duration-300"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
            >
              <ChevronRight size={24} />
            </motion.button>
          </div>
        )}
      </div>
    );
  };
  
  const BoxManager: React.FC = () => {
    const [boxes, setBoxes] = useState<BoxData[]>([]);
    const [selectedBox, setSelectedBox] = useState<BoxData | null>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isBulkUpdating, setIsBulkUpdating] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const ITEMS_PER_PAGE = 15;
  
    const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
    const [filters, setFilters] = useState<FilterState>({
      priceMin: 0,
      priceMax: 500000,
      categories: []
    });

    const availableCategories = useMemo(() => {
      const categoriesSet = new Set<string>();
      const categoriesArray: { name: string; slug: string; type: string; }[] = [];
      
      boxes.forEach(box => {
        box.categories.forEach(category => {
          const categoryKey = JSON.stringify(category);
          if (!categoriesSet.has(categoryKey)) {
            categoriesSet.add(categoryKey);
            categoriesArray.push(category);
          }
        });
      });
      
      return categoriesArray;
    }, [boxes]);

    const filteredBoxes = useMemo(() => {
      return boxes.filter(box => {
        // Apply search filter
        const searchLower = searchTerm.toLowerCase().trim();
        const matchesSearch = !searchLower || 
          box.name.toLowerCase().includes(searchLower) ||
          box.categories.some(cat => cat.name.toLowerCase().includes(searchLower)) ||
          box.tags.some(tag => tag.name.toLowerCase().includes(searchLower));
  
        // Apply price filter
        const matchesPrice = box.price >= filters.priceMin && box.price <= filters.priceMax;
  
        // Apply category filter
        const matchesCategory = filters.categories.length === 0 ||
          box.categories.some(cat => filters.categories.includes(cat.slug));
  
        return matchesSearch && matchesPrice && matchesCategory;
      });
    }, [boxes, searchTerm, filters]);

    const renderStats = () => (
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
        <StatCard 
          title="Total Boxes" 
          value={filteredBoxes.length}
          icon={<Package size={20} />}
          index={0}
        />
        <StatCard 
          title="Enabled Boxes" 
          value={filteredBoxes.filter(box => box.enabled).length}
          icon={<CheckCircle size={20} />}
          index={1}
        />
        <StatCard 
          title="Price Range" 
          value={`${(filters.priceMin / 100).toFixed(2)}€ - ${(filters.priceMax / 100).toFixed(2)}€`}
          icon={<DollarSign size={20} />}
          index={2}
        />
        <StatCard 
          title="Active Filters" 
          value={filters.categories.length + (filters.priceMin > 0 || filters.priceMax < 500000 ? 1 : 0)}
          icon={<LayersIcon size={20} />}
          index={3}
        />
      </div>
    );

  
  
    const renderBulkControls = () => (
      <div className="flex items-center gap-4">
        <motion.button
          onClick={() => handleBulkToggle(true)}
          disabled={isBulkUpdating}
          className="flex items-center gap-2 px-4 py-2 bg-gray-800 text-white rounded-lg hover:bg-emerald-500 transition-colors disabled:opacity-50 disabled:cursor-not-allowedflex items-center gap-2 px-4 py-2 bg-emerald-500 text-white rounded-lg hover:bg-emerald-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
          whileHover={{ scale: isBulkUpdating ? 1 : 1.05 }}
          whileTap={{ scale: isBulkUpdating ? 1 : 0.95 }}
        >
          <Eye size={18} />
          Enable All
        </motion.button>
        <motion.button
          onClick={() => handleBulkToggle(false)}
          disabled={isBulkUpdating}
          className="flex items-center gap-2 px-4 py-2 bg-[#EF4444] text-white rounded-lg hover:bg-[#DC2626] transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
          whileHover={{ scale: isBulkUpdating ? 1 : 1.05 }}
          whileTap={{ scale: isBulkUpdating ? 1 : 0.95 }}
        >
          <EyeOff size={18} />
          Disable All
        </motion.button>
      </div>
    );

    const fetchDisabledBoxes = async () => {
      try {
        const response = await fetch('https://empire-back.selaris.app/api/disabled');
        if (!response.ok) throw new Error('Failed to fetch disabled boxes');
        const data = await response.json();
        return new Set(data.data.map((box: any) => box.slug));
      } catch (error) {
        console.error('Error fetching disabled boxes:', error);
        return new Set();
      }
    };
  
    const fetchBoxes = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const [boxesResponse, disabledBoxes] = await Promise.all([
          fetch('https://api.empiredrop.com/api/v1/boxes/search', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              brands: [],
              categories: [],
              order: 'CHEAPEST',
              price_max: 500000,
              price_min: 0,
              tags: []
            })
          }),
          fetchDisabledBoxes()
        ]);
  
        if (!boxesResponse.ok) {
          throw new Error('Failed to fetch boxes');
        }
  
        const data = await boxesResponse.json();
        
        const transformedBoxes: BoxData[] = data.map((box: any) => ({
          name: box.name,
          slug: box.slug,
          image: box.image.startsWith('/')
            ? `https://cdn.empiredrop.com${box.image}`
            : `https://cdn.empiredrop.com/${box.image}`,
          price: box.price,
          tags: box.tags || [],
          enabled: !disabledBoxes.has(box.slug),
          categories: box.categories || []
        }));
  
        setBoxes(transformedBoxes);
      } catch (err) {
        console.error('Error fetching boxes:', err);
        setError('Failed to load boxes. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };
  
    useEffect(() => {
      fetchBoxes();
    }, []);
  
    const handleBulkToggle = async (enableAll: boolean) => {
      try {
        setIsBulkUpdating(true);
        
        // Find boxes that need to be updated
        const boxesToUpdate = boxes.filter(box => box.enabled !== enableAll);
    
        // Use Promise.all to handle all toggles simultaneously
        await Promise.all(
          boxesToUpdate.map(box => handleToggleBox(box.slug))
        );
    
      } catch (error) {
        console.error('Error during bulk update:', error);
        fetchBoxes();
      } finally {
        setIsBulkUpdating(false);
      }
    };
  
    const totalPages = Math.ceil(filteredBoxes.length / ITEMS_PER_PAGE);
    const currentBoxes = filteredBoxes.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE
    );
  
    const handleStartRandom = () => {
      const enabledBoxes = boxes.filter(box => box.enabled);
      if (enabledBoxes.length > 0) {
        const randomIndex = Math.floor(Math.random() * enabledBoxes.length);
        setSelectedBox(enabledBoxes[randomIndex]);
      }
    };
    const handleToggleBox = async (slug: string) => {
      try {
        const box = boxes.find(b => b.slug === slug);
        if (!box) return;
    
        const newEnabled = !box.enabled;
        
        await fetch(`https://api.empiredrop.com/api/v1/boxes/search`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                brands: [],
                categories: [],
                order: 'CHEAPEST',
                price_max: 500000,
                price_min: 0,
                tags: []
            })
        });
    
        const response = await fetch(`https://empire-back.selaris.app/api/toggle/${slug}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            isEnabled: newEnabled
          })
        });
    
        if (!response.ok) {
          throw new Error('Failed to toggle box state');
        }
    
        setBoxes(prevBoxes => 
          prevBoxes.map(box => 
            box.slug === slug ? { ...box, enabled: newEnabled } : box
          )
        );
      } catch (error) {
        console.error('Error toggling box:', error);
        fetchBoxes();
      }
    };

    return (
      <div className="p-6 space-y-6">
        <RandomBoxSelector selectedBox={selectedBox} onStart={handleStartRandom} />
    
        <div className="bg-gradient-to-br from-[#1A1C23] to-[#252A34] p-6 rounded-xl shadow-2xl border border-[#3D4149]">
          {!isLoading && !error && boxes.length > 0 && renderStats()}
    
          <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4 mb-8">
            <div className="flex items-center gap-4">
              <Box size={24} className="text-[#611BE2]" />
              <h2 className="text-2xl font-bold text-white">Available Boxes</h2>
            </div>
            <div className="flex flex-col sm:flex-row items-end sm:items-center gap-4">
              {renderBulkControls()}
              <div className="relative flex-1 sm:flex-none">
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#611BE2]" size={20} />
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full sm:w-64 bg-[#2C3038] text-white pl-10 pr-4 py-2 rounded-lg border-2 border-[#3D4149] focus:border-[#611BE2] focus:outline-none transition-all duration-300"
                  placeholder="Search boxes, categories, tags..."
                />
              </div>
              <div className="relative">
                <motion.button 
                  onClick={() => setIsFilterMenuOpen(!isFilterMenuOpen)}
                  className={`p-2 rounded-lg transition-all duration-300 ${
                    isFilterMenuOpen || filters.categories.length > 0 || filters.priceMin > 0 || filters.priceMax < 500000
                      ? 'bg-[#611BE2] text-white'
                      : 'hover:bg-[#611BE2]/10 text-[#611BE2]'
                  }`}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <Filter size={20} />
                </motion.button>
                <AnimatePresence>
                  {isFilterMenuOpen && (
                    <FilterMenu
                      isOpen={isFilterMenuOpen}
                      onClose={() => setIsFilterMenuOpen(false)}
                      filters={filters}
                      onFiltersChange={setFilters}
                      availableCategories={availableCategories}
                    />
                  )}
                </AnimatePresence>
              </div>
            </div>
          </div>
    
          <AnimatePresence mode="wait">
            {isLoading ? (
              <LoadingSpinner />
            ) : error ? (
              <ErrorMessage message={error} onRetry={fetchBoxes} />
            ) : filteredBoxes.length === 0 ? (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="flex flex-col items-center justify-center py-12 text-gray-400"
              >
                <Box size={48} className="text-[#611BE2] mb-4" />
                {searchTerm || filters.categories.length > 0 || filters.priceMin > 0 || filters.priceMax < 500000 ? (
                  <>
                    <p className="text-lg mb-2">No boxes found matching your filters</p>
                    <motion.button
                      onClick={() => {
                        setSearchTerm('');
                        setFilters({
                          priceMin: 0,
                          priceMax: 500000,
                          categories: []
                        });
                      }}
                      className="text-[#611BE2] hover:text-[#8B5CF6] transition-colors"
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      Clear all filters
                    </motion.button>
                  </>
                ) : (
                  <p className="text-lg">No boxes available</p>
                )}
              </motion.div>
            ) : (
              <BoxGrid
                boxes={currentBoxes}
                onToggle={handleToggleBox}
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={setCurrentPage}
              />
            )}
          </AnimatePresence>
        </div>
      </div>
    );
  };
  
  export default BoxManager;
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Crown, Gift, Calendar, Award, Home, Grid, Headset, ChevronLeft, ChevronRight, ChevronDown, Gem, Ticket, Bot, Box, Server, FerrisWheel } from 'lucide-react';

interface SidebarProps {
  isOpen: boolean;
  toggleSidebar: () => void;
}

interface NavItemProps {
  icon: React.ElementType;
  label: string;
  to: string;
  badge?: string;
  badgeColor?: string;
  expandable?: boolean;
  isOpen: boolean;
}

const NavItem: React.FC<NavItemProps> = ({ 
  icon: Icon, 
  label, 
  to,
  badge, 
  badgeColor = "bg-[#611BE2]", 
  expandable = false,
  isOpen
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <motion.li
      className="mb-2"
      whileHover={{ scale: 1.02 }}
      transition={{ type: "spring", stiffness: 400, damping: 10 }}
    >
      <Link
        to={to}
        className={`flex items-center justify-between p-3 rounded-lg transition-all duration-300 ease-in-out hover:bg-[#1A1C23] group cursor-pointer ${isActive ? 'bg-[#1A1C23]' : ''}`}
        onClick={(e) => {
          if (expandable) {
            e.preventDefault();
            setIsExpanded(!isExpanded);
          }
        }}
      >
        <div className="flex items-center">
          <motion.div
            className={`w-10 h-10 flex items-center justify-center bg-[#1A1C23] rounded-lg group-hover:bg-[#611BE2] transition-all duration-300 ${isActive ? 'bg-[#611BE2]' : ''}`}
            whileHover={{ rotate: 360 }}
            transition={{ duration: 0.5 }}
          >
            <Icon className={`w-5 h-5 text-[#8B5CF6] group-hover:text-white ${isActive ? 'text-white' : ''}`} />
          </motion.div>
          {isOpen && (
            <motion.span 
              initial={{ opacity: 0, x: -10 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -10 }}
              transition={{ duration: 0.3 }}
              className={`ml-3 font-medium group-hover:text-white ${isActive ? 'text-white' : 'text-gray-300'}`}
            >
              {label}
            </motion.span>
          )}
        </div>
        {isOpen && (
          <div className="flex items-center">
            {badge && (
              <motion.span
                className={`text-xs px-2 py-1 rounded-full ${badgeColor} text-white font-semibold mr-2`}
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.3 }}
              >
                {badge}
              </motion.span>
            )}
            {expandable && (
              <motion.div
                animate={{ rotate: isExpanded ? 180 : 0 }}
                transition={{ duration: 0.3 }}
              >
                <ChevronDown className="w-4 h-4 text-gray-400 group-hover:text-white" />
              </motion.div>
            )}
          </div>
        )}
      </Link>
      <AnimatePresence>
        {isExpanded && expandable && isOpen && (
          <motion.ul
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
            className="ml-12 mt-2 space-y-2"
          >
            <motion.li 
              className="text-gray-400 hover:text-white cursor-pointer"
              whileHover={{ x: 5 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <Link to={`${to}/subitem1`}>Subitem 1</Link>
            </motion.li>
            <motion.li 
              className="text-gray-400 hover:text-white cursor-pointer"
              whileHover={{ x: 5 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <Link to={`${to}/subitem2`}>Subitem 2</Link>
            </motion.li>
            <motion.li 
              className="text-gray-400 hover:text-white cursor-pointer"
              whileHover={{ x: 5 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <Link to={`${to}/subitem3`}>Subitem 3</Link>
            </motion.li>
          </motion.ul>
        )}
      </AnimatePresence>
    </motion.li>
  );
};

const Sidebar: React.FC<SidebarProps> = ({ isOpen, toggleSidebar }) => {
  return (
    <motion.nav
      className="bg-gradient-to-b from-[#0E1015] to-[#1A1C23] h-full overflow-hidden flex-shrink-0 relative"
      initial={false}
      animate={{ 
        width: isOpen ? 280 : 80,
        transition: { duration: 0.3, ease: "easeInOut" }
      }}
    >
      <div className="flex flex-col h-full">
        <motion.div 
          className="p-4 flex justify-between items-center"
          initial={false}
          animate={{ 
            paddingLeft: isOpen ? 16 : 24,
            transition: { duration: 0.3, ease: "easeInOut" }
          }}
        >
          <AnimatePresence>
            {isOpen && (
              <motion.h1 
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -20 }}
                transition={{ duration: 0.3 }}
                className="text-white font-bold text-xl"
              >
                Dashboard
              </motion.h1>
            )}
          </AnimatePresence>
          <motion.button
            className="text-gray-400 hover:text-white p-2 rounded-full bg-[#1A1C23] hover:bg-[#611BE2] focus:outline-none transition-colors duration-300"
            onClick={toggleSidebar}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            {isOpen ? <ChevronLeft size={20} /> : <ChevronRight size={20} />}
          </motion.button>
        </motion.div>

        <motion.ul 
          className="flex-1 px-3 py-4 space-y-1 overflow-y-auto scrollbar-hide"
          initial={false}
          animate={{ 
            alignItems: isOpen ? "flex-start" : "center",
            transition: { duration: 0.3, ease: "easeInOut" }
          }}
        >
          <NavItem icon={Home} label="Home" to="/"  isOpen={isOpen} />
          <NavItem icon={Gem} label="Prize Editor" to="/prize" isOpen={isOpen} />
          <NavItem icon={FerrisWheel} label="Draw" to="/draw" isOpen={isOpen} />
          <NavItem icon={Box} label="Boxes" to="/boxes" isOpen={isOpen} />
          <NavItem icon={Ticket} label="Promo Code" to="/promo-code" isOpen={isOpen} />
          <NavItem icon={Server} label="Server Status" to="/status" badge='100% UP' isOpen={isOpen} />
          <NavItem icon={Calendar} label="Links" to="/link" isOpen={isOpen} />
        </motion.ul>
        
       
      </div>
    </motion.nav>
  );
};

export default Sidebar;
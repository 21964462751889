import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { gsap } from 'gsap';
import { Settings, MessageCircle, Command, Zap, Plus, Trash2 } from 'lucide-react';

const BotSettings: React.FC = () => {
  const [botName, setBotName] = useState('');
  const [botPrefix, setBotPrefix] = useState('!');
  const [customCommands, setCustomCommands] = useState<string[]>([]);
  const [newCommand, setNewCommand] = useState('');

  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (formRef.current) {
      gsap.from(formRef.current.children, {
        opacity: 0,
        y: 20,
        stagger: 0.1,
        duration: 0.5,
        ease: 'power2.out',
      });
    }
  }, []);

  const handleAddCommand = () => {
    if (newCommand && !customCommands.includes(newCommand)) {
      setCustomCommands([...customCommands, newCommand]);
      setNewCommand('');
    }
  };

  const handleRemoveCommand = (command: string) => {
    setCustomCommands(customCommands.filter(cmd => cmd !== command));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

  };

  return (
    <div className="p-6 bg-[#1A1C23] rounded-xl shadow-lg">
      <h1 className="text-2xl font-bold text-white mb-6 flex items-center">
        <Settings className="mr-3 text-[#611BE2]" size={24} />
        Bot Settings
      </h1>

      <form ref={formRef} onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-400 mb-1">Bot Name</label>
          <div className="relative">
            <MessageCircle className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#611BE2]" size={18} />
            <input
              type="text"
              value={botName}
              onChange={(e) => setBotName(e.target.value)}
              className="w-full bg-[#252A34] text-white border border-[#3D4149] rounded-lg py-2 px-4 pl-10 focus:outline-none focus:border-[#611BE2] transition-colors duration-300"
              placeholder="Enter bot name"
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-400 mb-1">Command Prefix</label>
          <div className="relative">
            <Command className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#611BE2]" size={18} />
            <input
              type="text"
              value={botPrefix}
              onChange={(e) => setBotPrefix(e.target.value)}
              className="w-full bg-[#252A34] text-white border border-[#3D4149] rounded-lg py-2 px-4 pl-10 focus:outline-none focus:border-[#611BE2] transition-colors duration-300"
              placeholder="Enter command prefix"
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-400 mb-1">Custom Commands</label>
          <div className="flex space-x-2 mb-2">
            <div className="relative flex-grow">
              <Zap className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#611BE2]" size={18} />
              <input
                type="text"
                value={newCommand}
                onChange={(e) => setNewCommand(e.target.value)}
                className="w-full bg-[#252A34] text-white border border-[#3D4149] rounded-lg py-2 px-4 pl-10 focus:outline-none focus:border-[#611BE2] transition-colors duration-300"
                placeholder="Enter new command"
              />
            </div>
            <button
              type="button"
              onClick={handleAddCommand}
              className="bg-[#611BE2] text-white px-4 py-2 rounded-lg hover:bg-[#8B5CF6] transition-colors duration-300 flex items-center"
            >
              <Plus size={18} />
            </button>
          </div>
          <div className="space-y-2 max-h-40 overflow-y-auto">
            {customCommands.map((command, index) => (
              <motion.div
                key={index}
                className="flex items-center justify-between bg-[#252A34] p-2 rounded-lg"
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.2 }}
              >
                <span className="text-white">{command}</span>
                <button
                  type="button"
                  onClick={() => handleRemoveCommand(command)}
                  className="text-red-500 hover:text-red-400 transition-colors duration-300"
                >
                  <Trash2 size={18} />
                </button>
              </motion.div>
            ))}
          </div>
        </div>

        <motion.button
          type="submit"
          className="w-full bg-[#611BE2] text-white py-2 rounded-lg font-semibold transition duration-300 hover:bg-[#8B5CF6]"
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          Save Bot Settings
        </motion.button>
      </form>
    </div>
  );
};

export default BotSettings;